import React from 'react';
import { ContentItem } from '../../../../../core/models/content-item.model';
import { IconButton, Stack } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../../../../assets/delete.svg';
import { useContentItemRepository } from '../../../../../core/repository/useContentItemRepository';
import { useConfirm } from 'material-ui-confirm';

interface ITableActionsProps {
  contentItem: ContentItem;
}

const TableActions: React.FC<ITableActionsProps> = ({ contentItem }) => {
  const contentItemRepository = useContentItemRepository();
  const confirm = useConfirm();

  return (
    <Stack width={40} direction={'row'} gap={0.5}>
      <IconButton onClick={(e) => handleDeleteClicked(e)}>
        <DeleteIcon />
      </IconButton>
    </Stack>
  );

  function handleDeleteClicked(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();
    confirm({
      title: 'Delete content item',
      description: 'Are you sure you want to delete this content item?',
      confirmationText: 'Delete',
    })
      .then(async () => {
        await contentItemRepository.deleteContentItem(contentItem.id);
      })
      .catch(() => {
        // do nothing
      });
  }
};

export default TableActions;
