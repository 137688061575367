import { Box, Stack, Typography, styled } from '@mui/material';
import { FC } from 'react';
import { useCompanyStore } from '../store/company-store/useCompanyStore';
import { useSessionStore } from '../store/session-store';
import { colors, dimensions } from '../theme';
import { UserMenu } from './UserMenu.component';

const StyledHeader = styled(Box, { name: 'Header', slot: 'header' })({
  height: dimensions.header.height,
  marginBottom: dimensions.header.margin.bottom,
  padding: `${dimensions.header.padding.Y} ${dimensions.header.padding.X} ${dimensions.header.padding.Y} ${dimensions.header.padding.X}`,
});

export const Header: FC = () => {
  const selfUser = useSessionStore((state) => state.selfUser);
  const { selectedCompany } = useCompanyStore();

  return (
    <StyledHeader>
      <UserMenu />

      {!selfUser?.isSuperAdmin && (
        <Stack>
          <Typography fontSize={12} color={colors.grey}>
            Logged in as {selfUser?.firstName} {selfUser?.lastName} at
          </Typography>
          <Typography fontSize={24}>{selectedCompany?.name}</Typography>
        </Stack>
      )}
    </StyledHeader>
  );
};
