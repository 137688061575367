import { adminStore, AdminStore } from './admin-store';

export const useAdminStore = (): AdminStore => {
  const store = adminStore();

  if (!store.admins && store.isLoading === false) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    store.refresh();
  }

  return store;
};
