import React, { useMemo } from 'react';
import { UpdateSortingDto } from '../../../../core/api/data/dto/update-sorting.dto';
import { DraggableTable, TableRow } from '../../../../core/components/table';
import { useContentItemRepository } from '../../../../core/repository/useContentItemRepository';
import { useContentItemStore } from '../../../../core/store/content-item-store';
import { convertItemToTableRow } from './table-fields/content-item-table-row.helper';

interface ContentItemTableProps {
  handleClickOnRow: (id: string) => void;
}

export const ContentItemTableComponent: React.FC<ContentItemTableProps> = ({
  handleClickOnRow,
}) => {
  const contentItemRepo = useContentItemRepository();
  const contentItemStore = useContentItemStore();

  const headers = useMemo(
    () => ['Icon', 'Label', 'Description', 'Validity', 'Visibility', 'URL', ''],
    []
  );

  const rows: TableRow[] | undefined = useMemo(
    () =>
      contentItemStore.contentItems?.map((contentItem) =>
        convertItemToTableRow(contentItem, () => handleClick(contentItem.id))
      ),
    [contentItemStore.contentItems]
  );

  return (
    <DraggableTable
      headers={headers}
      rows={rows ?? []}
      isLoading={contentItemStore.loading}
      onReorderItems={handleReorderItems}
    />
  );

  function handleClick(id: string) {
    handleClickOnRow(id);
  }

  function handleReorderItems(items: TableRow[]) {
    contentItemRepo
      .updateSortingOfContentItems(
        items.map(
          (item, index) =>
            ({ id: item.id, position: index } as UpdateSortingDto)
        )
      )
      //these updates are optimistic
      .then(() => console.debug('reordered!'))
      .catch(() => console.error('reorder failed'));
  }
};
