import { Stack } from '@mui/material';
import { FC } from 'react';
import { ReactComponent as ValidSvg } from '../../../../assets/checkmark.svg';
import { ReactComponent as InvalidSvg } from '../../../../assets/invalid.svg';
import { policyPatterns } from './password-policy-patterns';

type PolicyRules = {
  minLength?: number;
  lowercase?: boolean;
  uppercase?: boolean;
  numbers?: boolean;
};

type PasswordPolicyHintProps = {
  value: string;
  policy: PolicyRules;
};

export const PasswordPolicyHint: FC<PasswordPolicyHintProps> = ({
  value,
  policy,
}) => {
  const { lowercase, uppercase, numbers } = policyPatterns;

  return (
    <Stack sx={{ fontSize: '14px' }}>
      <span style={{ marginBottom: '4px' }}>Password must contain</span>
      {policy.minLength !== undefined && (
        <PolicyItem
          description={`${policy.minLength} or more characters`}
          valid={value.length >= policy.minLength}
        />
      )}

      {!!policy.lowercase && !!policy.uppercase && (
        <PolicyItem
          description={`Upper and lower case letters`}
          valid={lowercase.test(value) && uppercase.test(value)}
        />
      )}

      {!!policy.lowercase && !policy.uppercase && (
        <PolicyItem
          description={`Lower case letters`}
          valid={lowercase.test(value)}
        />
      )}

      {!policy.lowercase && !!policy.uppercase && (
        <PolicyItem
          description={`Upper case letters`}
          valid={uppercase.test(value)}
        />
      )}

      {!!policy.numbers && (
        <PolicyItem
          description={`At least one number`}
          valid={numbers.test(value)}
        />
      )}
    </Stack>
  );
};

type PolicyItemProps = {
  description: string;
  valid: boolean;
};

const PolicyItem: FC<PolicyItemProps> = ({ description, valid }) => {
  return (
    <Stack
      flexDirection={'row'}
      alignItems={'center'}
      sx={{ lineHeight: '24px' }}
    >
      {valid ? <ValidSvg /> : <InvalidSvg />}{' '}
      <span style={{ paddingLeft: '8px' }}>{description}</span>
    </Stack>
  );
};
