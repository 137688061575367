/* eslint-disable @typescript-eslint/no-explicit-any */
import { client } from '../api/api.client';
import { JSONValue } from '../api/types';
import { Company } from '../models/company.model';

type CompanyRepository = {
  getCompanies: () => Promise<Company[]>;
};

const repository = (): CompanyRepository => {
  return {
    getCompanies: async (): Promise<Company[]> => {
      const companiesJson = await client.getCompanies();
      const companies = companiesJson.map((company: JSONValue) =>
        Company.fromJson(company as { [x: string]: any })
      );

      return companies;
    },
  };
};

export const companyRepository = repository();
