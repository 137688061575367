import { DateTime } from 'luxon';
import React from 'react';
import { TableRow } from '../../../../../core/components/table';
import { ContentItem } from '../../../../../core/models/content-item.model';
import { formatSupabaseDateString } from '../../../../../core/utils/date-time-formatter';
import { truncateString } from '../../../../../core/utils/truncate-string';
import ContentItemTableIcon from './ContentItemTableIcon.component';
import TableActions from './TableActions.component';

export const convertItemToTableRow = (
  item: ContentItem,
  handleClickOnRow: VoidFunction
): TableRow => {
  return {
    onClick: handleClickOnRow,
    id: item.id,
    elements: [
      <ContentItemTableIcon
        key={`table-icon-${item.id}`}
        iconUrl={item.imageUrl}
      />,
      item.title,
      getItemDescription(item),
      getValidityString(item),
      getVisibilityString(item),
      item.link,
      getActionButtons(item),
    ],
  };
};

const getItemDescription = (item: ContentItem): string => {
  return truncateString(item.description || '', 100);
};

const getValidityString = (item: ContentItem): string => {
  if (item.validFrom == null && item.validUntil == null) {
    return 'Always';
  }
  return `${formatSupabaseDateString(
    item.validFrom,
    DateTime.DATE_SHORT
  )} to ${formatSupabaseDateString(item.validUntil, DateTime.DATE_SHORT)}`;
};

const getVisibilityString = (item: ContentItem): string => {
  return item.userCanHideItem
    ? item.defaultShownInApp
      ? 'variable (show)'
      : 'variable (hide)'
    : 'Always visible';
};

const getActionButtons = (item: ContentItem): React.ReactNode => {
  return <TableActions contentItem={item} />;
};
