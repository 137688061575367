import { useEffect, useState } from 'react';

import { Button } from '../../core/components/button';
import { MainPageLayout } from '../../core/components/layout';
import { User } from '../../core/models/user.model';
import { useUserRepository } from '../../core/repository/useUserRepository';
import { useCompanyStore } from '../../core/store/company-store/useCompanyStore';
import { UserDialog } from './UserDialog.component';
import { UserTable } from './UserTable.component';

type Indeces = {
  startIndex: number | undefined;
  endIndex: number | undefined;
};

export const UserManagement = () => {
  //region STATE
  const userRepository = useUserRepository();

  const [users, setUsers] = useState<User[]>([]);
  const [profilePictures, setProfilePictures] = useState<(Blob | null)[]>([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [indeces, setIndeces] = useState<Indeces>({
    startIndex: undefined,
    endIndex: undefined,
  });
  const { selectedCompany } = useCompanyStore();

  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | undefined>();
  //endregion

  useEffect(() => {
    if (selectedCompany?.id !== undefined) {
      getUsersForRange(indeces.startIndex, indeces.endIndex)
        .then(() => console.debug('fetched users'))
        .catch(console.error);
    }
  }, [indeces, selectedCompany?.id]);

  //region HANDLERS
  const getUsersForRange = async (startIndex?: number, endIndex?: number) => {
    if (startIndex !== undefined && endIndex !== undefined) {
      setIsLoading(true);

      try {
        const { users: tempUsers, totalCount } =
          await userRepository.getUsersForRange(startIndex, endIndex);

        const tempProfilePictures = await Promise.all(
          tempUsers.map(async (user: User): Promise<Blob | null> => {
            if (user.id === undefined) return null;

            return userRepository.getProfilePicture(user.id);
          })
        );
        setUsers(tempUsers);
        setProfilePictures(tempProfilePictures);
        setTotalUsers(totalCount);
      } catch (e) {
        console.error(e);
        return;
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onDialogClose = async (user?: User) => {
    if (user) {
      await getUsersForRange(indeces.startIndex, indeces.endIndex);
    }
    setOpenUserDialog(false);
    setSelectedUser(undefined);
  };

  const editUser = (user: User) => {
    setSelectedUser(user);
    setOpenUserDialog(true);
  };
  //endregion

  return (
    <MainPageLayout
      action={
        <Button
          variant="contained"
          sx={{ width: 200 }}
          onClick={() => setOpenUserDialog(true)}
        >
          + Add user
        </Button>
      }
      title={'User Management'}
    >
      {openUserDialog && (
        <UserDialog onClose={onDialogClose} selectedUser={selectedUser} />
      )}
      <UserTable
        isLoading={isLoading}
        users={users}
        profilePictures={profilePictures}
        onUserClick={(user: User) => editUser(user)}
        totalUsers={totalUsers}
        onPageChange={(startIndex: number, endIndex: number) =>
          setIndeces({ startIndex, endIndex })
        }
      />
    </MainPageLayout>
  );
};
