import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Dialog } from '../../../../core/components/dialog';
import ContentItemForm from './ContentItemForm';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  ContentItemFormValues,
  fromContentItem,
  getDefaultValues,
} from '../../../../core/models/content-item-form-values';
import { Button } from '../../../../core/components/button';
import { useContentItemRepository } from '../../../../core/repository/useContentItemRepository';
import { ContentItem as ContentItemModel } from '../../../../core/models/content-item.model';

export interface IContentItemActions {
  setOpen: (open: boolean) => void;
  openWithItem: (item: ContentItemModel) => void;
}

const ContentItem: React.ForwardRefRenderFunction<IContentItemActions, {}> = (
  _props,
  ref
) => {
  const contentRepository = useContentItemRepository();

  const form = useForm({
    defaultValues: getDefaultValues(undefined),
    mode: 'onChange',
  });
  const [dialogState, setDialogState] = useState<false | 'edit' | 'create'>(
    false
  );

  useImperativeHandle(ref, () => ({
    setOpen: () => {
      setDialogState('create');
    },
    openWithItem: (item: ContentItemModel) => {
      form.reset(fromContentItem(item));
      setDialogState('edit');
    },
  }));

  const handleSubmit = form.handleSubmit((data: ContentItemFormValues) => {
    getSavePromise(data)
      .then(() => {
        setDialogState(false);
        form.reset();
      })
      .catch((e) => {
        console.error(e);
        //todo: error handling
      });
  });

  return (
    <>
      <Dialog
        onClose={handleClose}
        fullWidth
        maxWidth={'sm'}
        open={dialogState != false}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>Create Cross Content Item</DialogTitle>
          <DialogContent>
            <ContentItemForm formProps={form} />
          </DialogContent>
          <DialogActions>
            <Button variant={'outlined'} onClick={handleClose}>
              Cancel
            </Button>
            <Button type={'submit'}>Save</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );

  function handleClose() {
    setDialogState(false);
    form.reset(getDefaultValues(undefined));
  }

  function getSavePromise(data: ContentItemFormValues) {
    if (dialogState == 'create')
      return contentRepository.createContentItem(data);
    if (dialogState == 'edit')
      return contentRepository.updateContentItem(
        data,
        form.formState.dirtyFields
      );
    return Promise.reject('Invalid dialog state');
  }
};

export default forwardRef(ContentItem);
