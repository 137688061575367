import {
  Table as MuiTable,
  TableContainer,
  TableHead,
  TablePagination,
} from '@mui/material';
import { FC, ReactNode, useEffect, useState } from 'react';
import {
  StyledHeader,
  StyledHeaderCell,
  StyledTableContainer,
} from '../styles';
import { TableRow } from '../types';
import { TableBody } from './TableBody.component';

type TableProps = {
  headers: ReactNode[];
  rows: TableRow[];
  isLoading?: boolean;
  totalRows?: number;
  usePagination: boolean;
  onPageChange?: (startIndex: number, endIndex: number) => void;
};

const rowsPerPageOptions = [25, 50];

export const Table: FC<TableProps> = ({
  headers,
  rows,
  isLoading = false,
  usePagination,
  totalRows,
  onPageChange,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  useEffect(() => {
    if (onPageChange !== undefined) {
      const startIndex = page * rowsPerPage;
      const endIndex = startIndex + rowsPerPage - 1;
      onPageChange(startIndex, endIndex);
    }
  }, [page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to first page with new rows per page
  };

  return (
    <TableContainer component={StyledTableContainer}>
      <MuiTable>
        <TableHead>
          <StyledHeader>
            {headers.map((header, headerIndex) => (
              <StyledHeaderCell
                key={headerIndex}
                header={header}
                index={headerIndex}
                headerCount={headers.length}
              />
            ))}
          </StyledHeader>
        </TableHead>
        <TableBody
          columnCount={headers.length}
          rows={rows}
          isLoading={isLoading}
        />
      </MuiTable>

      {usePagination && (
        <TablePagination
          showFirstButton={true}
          showLastButton={true}
          component="div"
          count={totalRows || rows.length}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
};
