/* eslint-disable @typescript-eslint/lines-between-class-members */
import { instanceToPlain } from 'class-transformer';
import { UUID } from './typedefs';

export type FeatureFlags = FeatureFlag[];

export class FeatureFlag {
  id?: UUID;
  key: string;
  value: boolean;

  constructor({ id, key, value }: { id: UUID; key: string; value: boolean }) {
    this.id = id;
    this.key = key;
    this.value = value;
  }

  static fromJson(jsonMap: { [x: string]: unknown }) {
    return new FeatureFlag({
      id: jsonMap.id as string,
      key: jsonMap.key as string,
      value: jsonMap.value as boolean,
    });
  }

  static toJson(featureFlag: FeatureFlag): { [x: string]: unknown } {
    return instanceToPlain(featureFlag);
  }
}
