import { ContentItem } from './content-item.model';

export enum ContentItemVisibility {
  ALWAYS_VISIBLE,
  VISIBLE_BY_DEFAULT,
  HIDDEN_BY_DEFAULT,
}

export const getVisibility = (contentItem?: ContentItem) => {
  if (contentItem) {
    if (contentItem.userCanHideItem) {
      if (contentItem.defaultShownInApp) {
        return ContentItemVisibility.VISIBLE_BY_DEFAULT;
      } else {
        return ContentItemVisibility.HIDDEN_BY_DEFAULT;
      }
    }
  }
  return ContentItemVisibility.ALWAYS_VISIBLE;
};
