import { create } from 'zustand';
import { FeatureFlag } from '../models/feature-flag.model';
import { featureFlagRepository } from '../repository/feature-flag-repository';

type State = {
  featureFlags: FeatureFlag[];
  loading: boolean;
};

type Actions = {
  getFeatureFlags: () => void;
  toggleFeatureFlag: (featureFlag: FeatureFlag) => Promise<void>;
};

type FeatureFlagStore = State & Actions;

const defaultFeatureFlags: FeatureFlag[] = [
  {
    key: 'chat_enabled',
    value: true,
  },
  {
    key: 'cross_content_hub_enabled',
    value: true,
  },
];

const initialState = {
  featureFlags: defaultFeatureFlags,
  loading: false,
};

export const useFeatureFlagStore = create<FeatureFlagStore>((set) => ({
  ...initialState,

  getFeatureFlags: async () => {
    set({ loading: true });

    const response: FeatureFlag[] =
      await featureFlagRepository.getFeatureFlags();

    set({
      featureFlags: mergeWithDefaults(response),
      loading: false,
    });
  },

  toggleFeatureFlag: async (featureFlag: FeatureFlag): Promise<void> => {
    const updatedFeatureFlag: FeatureFlag =
      await featureFlagRepository.toggleFeatureFlag(featureFlag);

    set((state) => {
      const index = state.featureFlags?.findIndex(
        (flag) => flag.key === featureFlag.key
      );

      if (index > -1) {
        const newFeatureFlags = [...state.featureFlags];
        newFeatureFlags[index] = updatedFeatureFlag;

        return { featureFlags: newFeatureFlags };
      } else {
        throw new Error('Feature flag not found');
      }
    });
  },
}));

function mergeWithDefaults(newFeatureFlags: FeatureFlag[]): FeatureFlag[] {
  const featureFlags: FeatureFlag[] = [...defaultFeatureFlags];

  newFeatureFlags.forEach((newFeatureFlag) => {
    const index = featureFlags.findIndex(
      (flag) => flag.key === newFeatureFlag.key
    );

    if (index > -1) {
      featureFlags[index] = newFeatureFlag;
    }
  });

  return featureFlags;
}
