import { client } from '../api/api.client';

type SessionRepository = {
  logIn: (email: string, password: string, companyId: string) => Promise<void>;
  logOut: () => Promise<void>;
  isAuthenticated: () => Promise<boolean>;
};

const repository = (): SessionRepository => {
  return {
    logIn: async (email: string, password: string, companyKey: string) => {
      await client.logIn(email, password, companyKey);
    },

    logOut: async () => {
      await client.logOut();
    },

    isAuthenticated: async () => {
      return client.isAuthenticated();
    },
  };
};

export const sessionRepository = repository();
