import { TextField } from '@mui/material';
import { FC, useState } from 'react';
import { Button } from '../button';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../dialog';

type PasswordConfirmationDialogProps = {
  open: boolean;
  description: string;
  onClose: () => void;
  onConfirm: (password: string) => void;
};

export const PasswordConfirmationDialog: FC<
  PasswordConfirmationDialogProps
> = ({ open, description, onClose, onConfirm }) => {
  const [password, setPassword] = useState('');

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Current Password</DialogTitle>
      <DialogContent>
        <p>{description}</p>
        <TextField
          autoFocus
          margin="dense"
          label="Current Password"
          type="password"
          fullWidth
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={password.length < 1}
          onClick={() => onConfirm(password)}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
