import { Table as MuiTable, TableContainer, TableHead } from '@mui/material';
import _ from 'lodash';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import {
  StyledHeader,
  StyledHeaderCell,
  StyledTableContainer,
} from '../styles';
import { TableRow } from '../types';
import { DraggableTableBody } from './DraggableTableBody.component';

type DraggableTableProps = {
  headers: ReactNode[];
  rows: TableRow[];
  isLoading?: boolean;
  onReorderItems: (newRows: TableRow[]) => void;
};

export const DraggableTable: FC<DraggableTableProps> = ({
  headers,
  rows,
  isLoading,
  onReorderItems,
}) => {
  const draggableHeaders = [...headers, ''];
  const [draggableRows, setDraggableRows] = useState<TableRow[]>([]);

  const debouncedUpdateRowsCallback = _.debounce(
    (debouncedUpdateRows: TableRow[]) => {
      onReorderItems(debouncedUpdateRows);
    },
    1000
  );

  const updateRowsCallback = useCallback(
    (updateRows: TableRow[]) => debouncedUpdateRowsCallback(updateRows),
    []
  );

  useEffect(() => {
    setDraggableRows(rows);
  }, [rows]);

  const handleUpdateRows = (updateRows: TableRow[]) => {
    updateRowsCallback(updateRows);
    setDraggableRows(updateRows);
  };

  return (
    <TableContainer component={StyledTableContainer}>
      <MuiTable>
        <TableHead>
          <StyledHeader>
            {draggableHeaders.map((header, headerIndex) => (
              <StyledHeaderCell
                key={headerIndex}
                header={header}
                index={headerIndex}
                headerCount={draggableHeaders.length}
              />
            ))}
          </StyledHeader>
        </TableHead>
        <DraggableTableBody
          columnCount={draggableHeaders.length}
          rows={draggableRows}
          isLoading={isLoading}
          handleUpdateRows={handleUpdateRows}
        />
      </MuiTable>
    </TableContainer>
  );
};
