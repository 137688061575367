/* eslint-disable @typescript-eslint/no-unsafe-assignment
 */
import { Box, TextField, styled } from '@mui/material';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { colors } from '../../theme';
import { BasicFormProps, ValidationRules } from './types';

const StyledBox = styled(Box, { name: 'TextFormField', slot: 'text-box' })({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  borderColor: colors.primary,
  '& label.Mui-focused': {
    color: colors.primary,
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.primary,
  },
});

export const StyledError = styled('span', {
  name: 'TextFormField',
  slot: 'error',
})({
  marginLeft: '16px',
  marginTop: '4px',
  color: colors.error,
  fontSize: '12px',
});

type TextFormFieldProps = {
  rules?: ValidationRules;
} & BasicFormProps;

export const TextFormField: FC<TextFormFieldProps> = ({
  control,
  controlName,
  rules,
  ...rest
}) => {
  const { sx, ...props } = rest;

  return (
    <Controller
      control={control}
      rules={rules}
      name={controlName}
      render={({
        field,
        fieldState: { isTouched, error },
        formState: { isSubmitted },
      }) => {
        return (
          <StyledBox sx={sx}>
            <TextField {...props} {...field} variant="outlined" />
            {(isTouched || isSubmitted) && error && (
              <StyledError role="alert">
                {error.message?.toString() || 'Invalid input'}
              </StyledError>
            )}
          </StyledBox>
        );
      }}
    ></Controller>
  );
};
