import { Stack } from '@mui/material';
import { FC } from 'react';
import { BasicFormProps } from '../types';
import { PasswordFormField } from './PasswordFormField.component';
import { PasswordPolicyHint } from './PasswordPolicyHint.component';
import { policyPatterns } from './password-policy-patterns';

type PasswordFormFieldWithHintProps = {
  value: string;
  show?: boolean;
  onFocus?: () => void;
  required?: boolean;
  disabled?: boolean;
} & Pick<BasicFormProps, 'control'>;

const minLength = 8;

export const PasswordFormFieldWithHint: FC<PasswordFormFieldWithHintProps> = ({
  control,
  value,
  show = true,
  required = true,
  disabled = false,
  onFocus,
}) => {
  return (
    <Stack direction={'row'} spacing={'37px'}>
      <PasswordFormField
        disabled={disabled}
        control={control}
        controlName="password"
        label="Password"
        sx={{ width: '50%' }}
        rules={{
          required,
          minLength,
          pattern: new RegExp(
            policyPatterns.lowercase.source +
              policyPatterns.uppercase.source +
              policyPatterns.numbers.source
          ),
        }}
        onFocus={onFocus}
      />
      <div style={{ width: '50%' }}>
        {show && (
          <PasswordPolicyHint
            value={value}
            policy={{
              minLength,
              lowercase: true,
              uppercase: true,
              numbers: true,
            }}
          />
        )}
      </div>
    </Stack>
  );
};
