import { Stack } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';

import { Button } from '../../core/components/button';
import { PasswordConfirmationDialog } from '../../core/components/confirmation-dialog/PasswordConfirmationDialog';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../core/components/dialog';
import { EmailFormField } from '../../core/components/form/EmailFormField.component';
import { PasswordFormFieldWithHint } from '../../core/components/form/PasswordFormField';
import { TextFormField } from '../../core/components/form/TextFormField.component';
import { useForm } from '../../core/hooks/useForm';
import { Admin, AdminType } from '../../core/models/admin.model';
import { UUID } from '../../core/models/typedefs';
import { adminRepository } from '../../core/repository';
import { useThrowError } from '../../core/store/error.store';

type FormValues = {
  id?: UUID;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  [key: string]: string | UUID | undefined;
};

type AdminDialogProps = {
  onClose: (admin?: Admin) => void;
  selectedAdmin?: Admin;
};

export const AdminDialog: FC<AdminDialogProps> = ({
  selectedAdmin,
  onClose,
}) => {
  //region STATE
  const [showPasswordPolicyHint, setShowPasswordPolicyHint] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const throwError = useThrowError();

  const {
    control,
    formValues,
    hasChanged: formHasChanged,
    reset: resetForm,
  } = useForm<FormValues>({
    defaultValues: useMemo(() => {
      return getDefaultValues(selectedAdmin);
    }, [selectedAdmin]),
  });

  const isNewAdmin = !selectedAdmin?.id;
  const needPasswordConfirmation =
    isNewAdmin || formValues.password?.length > 0;
  //endregion

  //region LIFECYCLE
  useEffect(() => {
    resetForm(getDefaultValues(selectedAdmin));
  }, [selectedAdmin]);
  //endregion

  //region HANDLER
  const submit = () => {
    if (needPasswordConfirmation) {
      setShowConfirmDialog(true);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      syncAdmin();
    }
  };

  const passwordConfirm = (confirmationPassword: string) => {
    setShowConfirmDialog(false);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    syncAdmin(confirmationPassword);
  };

  const syncAdmin = async (confirmationPassword?: string) => {
    const formUser: Admin = new Admin({
      ...formValues,
      type: AdminType.ADMIN,
    });

    setIsLoading(true);
    try {
      let response: Admin;
      if (isNewAdmin) {
        response = await adminRepository.addAdmin(
          formUser,
          confirmationPassword
        );
      } else {
        formUser.id = selectedAdmin.id;
        response = await adminRepository.updateAdmin(
          formUser,
          confirmationPassword
        );
      }
      onClose(response);
    } catch (e) {
      throwError(e);
      onClose(undefined);
    }
  };
  //endregion

  return (
    <>
      {showConfirmDialog && (
        <PasswordConfirmationDialog
          open={showConfirmDialog}
          description="Please enter your password to confirm the changes."
          onClose={onClose}
          onConfirm={passwordConfirm}
        />
      )}

      <Dialog fullWidth maxWidth={'sm'} open={true}>
        <DialogTitle>
          <Stack direction={'row'} alignItems={'center'} spacing={'24px'}>
            <span>
              {formValues.firstName} {formValues.lastName}
            </span>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <Stack
            alignItems={'center'}
            sx={{ minHeight: '278px', minWidth: 400 }}
          >
            <form style={{ width: '100%' }} onSubmit={submit} noValidate>
              <Stack sx={{ width: '100%' }} spacing={'36px'}>
                <Stack direction={'row'} spacing={'37px'}>
                  <TextFormField
                    disabled={isLoading}
                    control={control}
                    controlName="firstName"
                    label="First name"
                    rules={{ maxLength: 10 }}
                  ></TextFormField>
                  <TextFormField
                    disabled={isLoading}
                    control={control}
                    controlName="lastName"
                    label="Last name"
                  ></TextFormField>
                </Stack>
                <EmailFormField
                  disabled={isLoading}
                  control={control}
                  controlName="email"
                  label="E-Mail"
                />
                <PasswordFormFieldWithHint
                  required={isNewAdmin}
                  disabled={isLoading}
                  value={formValues.password}
                  control={control}
                  show={showPasswordPolicyHint}
                  onFocus={() => setShowPasswordPolicyHint(true)}
                />
              </Stack>
            </form>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            loading={isLoading}
            disabled={!formHasChanged || isLoading}
            onClick={submit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const getDefaultValues = (admin?: Admin): FormValues => {
  return {
    id: admin?.id,
    firstName: admin?.firstName ?? '',
    lastName: admin?.lastName ?? '',
    email: admin?.email ?? '',
    password: admin?.password ?? '',
  };
};
