/* eslint-disable @typescript-eslint/lines-between-class-members, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/dot-notation */
import { UUID } from './typedefs';

export enum AdminType {
  INFORM_ADMIN = 'inform_admin',
  ADMIN = 'admin',
}

export class Admin {
  id?: UUID;
  email: string;
  password?: string;
  type: AdminType;
  firstName?: string;
  lastName?: string;
  companyId?: string;
  isSuperAdmin?: boolean;

  constructor({
    id,
    email,
    type,
    password,
    firstName,
    lastName,
    companyId,
    isSuperAdmin,
  }: {
    id?: UUID;
    email: string;
    type: AdminType;
    password?: string;
    firstName?: string;
    lastName?: string;
    companyId?: string;
    isSuperAdmin?: boolean;
  }) {
    this.id = id;
    this.email = email;
    this.type = type;
    this.password = password;
    this.firstName = firstName;
    this.lastName = lastName;
    this.companyId = companyId;
    this.isSuperAdmin = isSuperAdmin;
  }

  static fromJson(json: { [x: string]: any }): Admin {
    return new Admin({
      id: json['id'],
      email: json['email'],
      type: AdminType[json['type'].toUpperCase() as keyof typeof AdminType],
      firstName: json['first_name'],
      lastName: json['last_name'],
      companyId: json['company_id'],
      isSuperAdmin: json['is_super_admin'],
    });
  }

  toJson(): {
    [x: string]: any;
  } {
    return {
      id: this.id ?? null,
      email: this.email,
      password:
        // we need some special handling here because the password is optional and the default value is ""
        this.password?.length !== undefined && this.password?.length > 0
          ? this.password
          : null,
      first_name: this.firstName,
      last_name: this.lastName,
    };
  }
}
