export class AuthApiError extends Error {
  private _status: number;

  get status() {
    return this._status;
  }

  constructor(message: string, status = -1) {
    super(message.length > 0 ? message : 'Unknown error');
    this._status = status;
  }
}
