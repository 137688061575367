// Credits: https://stackoverflow.com/questions/60906923/int32-to-hex-string-not-working-typescript
export const hexToRgbA = (hex: string, alpha = 1) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    c = ('0x' + c.join('')) as any;
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      `,${alpha})`
    );
  }
  throw new Error('Bad Hex');
};
