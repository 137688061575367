import { Stack, styled } from '@mui/material';
import { useEffect } from 'react';

import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import { useStateInitialization } from './core/hooks/useStateInitialization';
import { Layout } from './core/navigation/Layout.component';
import { routes } from './core/navigation/routes';
import { useUserRepository } from './core/repository/useUserRepository';
import { useSessionStore } from './core/store/session-store';
import { colors } from './core/theme';
import { AdminManagement } from './features/admin-management/AdminManagement.component';
import ContentItemManagement from './features/cross-content-hub/ContentItemManagement.component';
import { Login } from './features/login/Login.component';
import { Settings } from './features/settings/Settings.component';
import { UserManagement } from './features/user-management/UserManagement.component';

const UnauthorizedView = styled(Stack, {
  name: 'App',
  slot: 'unauthorized-view',
})(() => ({
  flex: '1 1 auto',
  display: 'flex',
  overflow: 'auto',
  height: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: `${colors.main.background}`,
}));

function App() {
  useStateInitialization();
  const isAuthenticated = useSessionStore((state) => state.isAuthenticated);
  const { setSelfUser, resetSelfUser } = useSessionStore();
  const userRepository = useUserRepository();

  async function getSelfUser() {
    const user = await userRepository.getSelfUser();
    setSelfUser(user);
  }

  useEffect(() => {
    if (isAuthenticated) {
      getSelfUser().catch((err) => {
        console.debug('Error while fetching user: ', err);
      });
    } else {
      resetSelfUser();
    }
  }, [isAuthenticated]);

  return (
    <BrowserRouter>
      <Routes>
        {!isAuthenticated && (
          <Route
            element={
              <UnauthorizedView>
                <Outlet />
              </UnauthorizedView>
            }
          >
            <Route path={routes.login} element={<Login />} />
            <Route path="*" element={<Navigate to={routes.login} />} />
          </Route>
        )}

        {isAuthenticated && (
          <Route element={<Layout />}>
            <Route path={routes.admins} element={<AdminManagement />} />
            <Route path={routes.users} element={<UserManagement />} />
            <Route
              path={routes.crossContentHub}
              element={<ContentItemManagement />}
            />
            <Route path={routes.settings} element={<Settings />} />
            <Route path="*" element={<Navigate to={routes.users} />} />
          </Route>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
