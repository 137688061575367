import { DateTime } from 'luxon';
export const usersJson: { [x: string]: any }[] = [
  {
    id: '5e53becc-006e-11ee-be56-0242ac120002',
    email: 'augustus.holland@mycompany.com',
    first_name: 'Augustus',
    last_name: 'Holland',
    user_name: '',
    last_sign_in_at: DateTime.now(),
  },
  {
    id: '50cb167e-006e-11ee-be56-0242ac120002',
    email: '',
    first_name: 'Jerry',
    last_name: 'Withers',
    user_name: '',
    last_sign_in_at: DateTime.now(),
  },
  {
    id: '4910b830-006e-11ee-be56-0242ac120002',
    email: 'nettie.moore@mycompany.com',
    first_name: 'Nettie',
    last_name: 'Moore',
    user_name: '',
    last_sign_in_at: DateTime.now(),
  },
];
