import { Reorder, useDragControls } from 'framer-motion';
import { FC } from 'react';
import { ReactComponent as DragIcon } from '../../../../assets/drag-handle.svg';
import { DataCells, LoadingRow, NoDataRow } from '../common';
import { TableRow } from '../types';

type DraggableTableBodyProps = {
  columnCount: number;
  rows: TableRow[];
  isLoading?: boolean;
  handleUpdateRows: (updateRows: TableRow[]) => void;
};

export const DraggableTableBody: FC<DraggableTableBodyProps> = ({
  columnCount,
  rows,
  isLoading = false,
  handleUpdateRows,
}) => {
  return (
    <Reorder.Group
      layoutScroll
      as={'tbody'}
      onReorder={handleUpdateRows}
      values={rows}
    >
      {isLoading ? (
        <LoadingRow columnCount={columnCount} />
      ) : rows.length === 0 ? (
        <NoDataRow columnCount={columnCount} />
      ) : (
        renderDataRows()
      )}
    </Reorder.Group>
  );

  function renderDataRows() {
    return rows.map((row, rowIndex) => {
      return (
        <DraggableTableRow
          onClick={row.onClick}
          key={`drag-row-${row.id}`}
          row={row}
          rowIndex={rowIndex}
        />
      );
    });
  }
};

const DraggableTableRow: FC<{
  row: TableRow;
  rowIndex: number;
  onClick?: () => void;
}> = ({ row, onClick }) => {
  const controls = useDragControls();

  return (
    <Reorder.Item
      dragListener={false}
      dragControls={controls}
      as={'tr'}
      value={row}
      key={row.id}
      onClick={(e) => {
        if (onClick) onClick();
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <DataCells elements={row.elements} />
      <td
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        style={{ cursor: 'pointer', userSelect: 'none' }}
        onPointerDown={(e) => {
          controls.start(e);
        }}
      >
        <DragIcon />
      </td>
    </Reorder.Item>
  );
};
