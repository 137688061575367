/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AuthError, createClient, PostgrestError } from '@supabase/supabase-js';

import { supabaseAnonKey, supabaseUrl } from '../../environment';
import { UUID } from '../models/typedefs';
import { AuthApiError } from './api-errors';
import { ApiClient } from './api.client';
import { ContentItemUpdateDto } from './data/dto/content-item-update.dto';
import { ContentItemDto } from './data/dto/content-item.dto';
import { UpdateSortingDto } from './data/dto/update-sorting.dto';
import { JSONArray, JSONObject } from './types';

import { companyStore } from '../store/company-store/company-store';
import { FeatureFlagDto } from './data/dto/feature-flag.dto';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const _client = createClient(
  !supabaseUrl ? 'http://localhost:8080' : supabaseUrl,
  !supabaseAnonKey ? 'nokey' : supabaseAnonKey
);

export const supabaseClient: ApiClient = {
  //////////////////
  // AUTHENTICATION
  //////////////////

  async logIn(
    email: string,
    password: string,
    companyKey: string
  ): Promise<void> {
    email = `${companyKey}+${email}`;
    const { data, error } = await _client.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      throw new AuthApiError(error.message, error.status);
    }

    const { data: isAdmin, error: adminError } = await _client.rpc('is_admin', {
      user_id: data?.user?.id,
    });

    if (adminError) throwError(adminError);
    if (isAdmin === false) {
      // we logged in before, so a valid session was created;
      // therefore we also need to clear the session if the user is no admin
      await this.logOut();
      throw new AuthApiError('User not authorized', 401);
    }
  },

  async logOut(): Promise<void> {
    try {
      await _client.auth.signOut();
    } catch (e) {
      console.error(e);
    }
  },

  async isAuthenticated(): Promise<boolean> {
    const { data, error } = await _client.auth.getSession();

    if (error) throwError(error);

    return data.session !== null;
  },

  //////////////////
  // ADMIN
  ///////////////////

  async getAdmins(): Promise<JSONArray> {
    const { data, error } = await _client
      .from('admin_profile')
      .select()
      .eq('company_id', getCompanyId());

    if (error) throwError(error);

    return dataOrThrowInvalidInput<JSONArray>(data, 'getAdmins');
  },

  async getAdmin(adminId: UUID): Promise<JSONObject> {
    const { data, error } = await _client
      .from('admin_profile')
      .select()
      .eq('id', adminId)
      .limit(1)
      .single();

    if (error) throwError(error);

    return dataOrThrowInvalidInput<JSONObject>(data, 'getAdmin');
  },

  async addAdmin(
    jsonMap: JSONObject,
    currentPassword?: string
  ): Promise<JSONObject> {
    const request = { ...jsonMap };

    request.user_id = null;
    delete request.id;
    request.auth_user_password = currentPassword ?? null;
    request.user_company_id = getCompanyId();

    const { data, error } = await _client.rpc(
      'add_or_update_admin_user',
      request
    );

    if (error) throwError(error);

    return dataOrThrowInvalidInput<JSONObject>(data, 'addAdmin');
  },

  async updateAdmin(
    jsonMap: JSONObject,
    currentPassword?: string
  ): Promise<JSONObject> {
    const request = { ...jsonMap };

    request.user_id = jsonMap.id;
    delete request.id;
    request.auth_user_password = currentPassword ?? null;
    request.user_company_id = getCompanyId();

    const { data, error } = await _client.rpc(
      'add_or_update_admin_user',
      request
    );

    if (error) throwError(error);

    return dataOrThrowInvalidInput<JSONObject>(data, 'updateAdmin');
  },

  async removeAdmin(adminId: UUID, currentPassword?: string): Promise<void> {
    // We can validate the password on the client and do not necessarily need to delete
    // an admin via a special rpc call. Even if an attacker would not use the UI and call
    // the api directly, he would only be able to delete admins but one due to our backend policy
    const { data: isPasswordValid, error: passwordError } = await _client.rpc(
      'validate_password',
      {
        password: currentPassword,
      }
    );

    if (!isPasswordValid) throw new Error('Invalid password');

    if (passwordError) throwError(passwordError);

    const { error } = await _client
      .from('admin_profile')
      .delete()
      .eq('id', adminId);

    if (error) throwError(error);
  },

  //////////////////
  // USER
  ///////////////////

  async getSelfUser(): Promise<JSONObject> {
    const { data: session, error: fetchSessionError } =
      await _client.auth.getUser();

    if (fetchSessionError) throwError(fetchSessionError);

    let profile = {};
    try {
      if (session?.user?.id) {
        profile = await this.getAdmin(session.user.id);
      }
    } catch (e) {
      throw new Error('Could not fetch admin profile');
    }

    const { data: isSuperAdmin, error: adminError } = await _client.rpc(
      'is_super_admin',
      { user_id: session.user?.id }
    );

    if (adminError) throwError(adminError);

    const data = { ...session.user, ...profile, is_super_admin: isSuperAdmin };

    return dataOrThrowInvalidInput<JSONObject>(data, 'getSelfUser');
  },

  async getUser(userId: UUID): Promise<JSONObject> {
    const { data, error } = await _client
      .from('profile')
      .select()
      .eq('id', userId)
      .limit(1)
      .single();

    if (error) throwError(error);

    return dataOrThrowInvalidInput<JSONObject>(data, 'getUser');
  },

  async getUsers(): Promise<JSONArray> {
    const { data, error } = await _client
      .from('profile')
      .select()
      .eq('company_id', getCompanyId());

    if (error) throwError(error);

    return dataOrThrowInvalidInput<JSONArray>(data, 'getUsers');
  },

  async getUsersForRange(
    start: number,
    end: number
  ): Promise<{ users: JSONArray; totalCount: number }> {
    const companyId = getCompanyId();
    const { data, count, error } = await _client
      .from('profile')
      .select('*', { count: 'exact' })
      .eq('company_id', companyId)
      .order('last_name', { ascending: true })
      .range(start, end);

    if (error) throwError(error);

    if (data === null) throw new Error(`[getUsersForRange] Invalid input`);

    return {
      users: data,
      totalCount: count ?? 0,
    };
  },

  async addUser(jsonMap: JSONObject): Promise<JSONObject> {
    const request = { ...jsonMap };

    request.user_id = null;
    delete request.id;
    request.auth_user_password = null;
    request.user_company_id = getCompanyId();
    request.new_user_name = request.user_name;
    delete request.user_name;

    const { data, error } = await _client.rpc('add_or_update_user', request);

    if (error) throwError(error);

    return dataOrThrowInvalidInput<JSONObject>(data, 'addUser');
  },

  async updateUser(
    jsonMap: JSONObject,
    currentPassword?: string
  ): Promise<JSONObject> {
    const request = { ...jsonMap };

    request.user_id = jsonMap.id;
    delete request.id;
    request.auth_user_password = currentPassword ?? null;
    request.user_company_id = getCompanyId();
    request.new_user_name = request.user_name;
    delete request.user_name;

    const { data, error } = await _client.rpc('add_or_update_user', request);

    if (error) throwError(error);

    return dataOrThrowInvalidInput<JSONObject>(data, 'updateUser');
  },

  //////////////////
  // COMPANY
  ///////////////////

  async getCompanies(): Promise<JSONArray> {
    const { data, error } = await _client.from('company').select();

    if (error) throwError(error);

    return dataOrThrowInvalidInput<JSONArray>(data, 'getCompanies');
  },

  //////////////////
  // PROFILE PICTURE
  //////////////////

  async getProfilePicture(pictureId: UUID): Promise<Blob | null> {
    // Check if a Profile Picture exists
    const { data, error } = await _client.storage
      .from('profile-pictures')
      .download(`${pictureId}.png`);

    if (error) return null;

    return data;
  },

  //////////////////
  // CROSS CONTENT ITEMS
  //////////////////

  async getCrossContentItems(): Promise<JSONArray> {
    const { data, error } = await _client.functions.invoke<JSONArray>(
      'cross-content-hub/admin',
      { method: 'GET' }
    );

    return data ?? [];
  },

  async createCrossContentItem(
    crossContentItem: ContentItemDto
  ): Promise<{ [p: string]: any }> {
    const { data, error } = await _client.functions.invoke(
      'cross-content-hub',
      {
        method: 'POST',
        body: crossContentItem.toJson(),
      }
    );

    return data;
  },

  async updateCrossContentItem(
    updateResource: ContentItemUpdateDto
  ): Promise<{ [p: string]: any }> {
    const { data, error } = await _client.functions.invoke(
      'cross-content-hub',
      {
        method: 'PUT',
        body: updateResource,
      }
    );

    return data;
  },

  async deleteCrossContentItem(id: UUID): Promise<void> {
    const { data, error } = await _client.functions.invoke(
      `cross-content-hub/${id}`,
      {
        method: 'DELETE',
      }
    );

    return data;
  },

  async updateSortingOfContentItems(
    ordering: UpdateSortingDto[]
  ): Promise<JSONArray> {
    const { data, error } = await _client.functions.invoke<JSONArray>(
      'cross-content-hub/change-order',
      {
        method: 'PUT',
        body: ordering,
      }
    );

    return data ?? [];
  },

  //////////////////
  // FEATURE FLAGS
  //////////////////

  async getFeatureFlags(): Promise<FeatureFlagDto[]> {
    const { data } = await _client
      .from('feature_flag')
      .select()
      .eq('company_id', getCompanyId());

    return dataOrThrowInvalidInput(data, 'getFeatureFlags');
  },

  async toggleFeatureFlag(
    featureFlag: FeatureFlagDto
  ): Promise<FeatureFlagDto> {
    if (featureFlag.id) {
      const { data, error } = await _client
        .from('feature_flag')
        .update(featureFlag)
        .eq('id', featureFlag.id)
        .select()
        .single();

      return data as FeatureFlagDto;
    } else {
      const companyId = getCompanyId();
      const { data, error } = await _client
        .from('feature_flag')
        .insert({ ...featureFlag, company_id: companyId })
        .select()
        .single();

      return data as FeatureFlagDto;
    }
  },

  //////////////////
  // FILE UPLOAD
  ///////////////////

  uploadFile(
    fileName: string,
    file: File,
    bucket: string,
    options: {
      replace: boolean;
    } = { replace: false }
  ): Promise<{ path: string }> {
    return new Promise<{ path: string }>((resolve, reject) => {
      _client.storage
        .from(bucket)
        .upload(fileName, file, {
          cacheControl: '3600',
          upsert: options.replace,
        })
        .then(({ data, error }) => {
          if (error) reject(error);
          else resolve(data);
        })
        .catch((_) => {});
    });
  },
};

function dataOrThrowInvalidInput<T>(data: any, methodName: string): T {
  if (data !== null) {
    return data as T;
  }

  throw new Error(`[${methodName}] Invalid input`);
}

function getCompanyId() {
  // TODO: later: maybe pass companyId to every API call instead of accessing zustand state here
  return companyStore.getState().selectedCompany?.id ?? '';
}

function throwError(error: AuthError | PostgrestError | null) {
  if (error) {
    if (error instanceof AuthError) {
      throw new AuthApiError(error.message, error.status);
    } else {
      throw new Error(`${error.message} [Details: ${error.details}]`);
    }
  }
  throw new Error('Unknown error');
}
