import { Admin } from '../../models/admin.model';
import { useAdminStore } from './useAdminStore';

export const useAdminByEmail = (email?: string): Admin | undefined => {
  const { admins } = useAdminStore();

  if (!admins || !email) {
    return;
  }

  const result = admins.find((admin) => admin.email === email);

  return result;
};
