import {
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
} from '@mui/material';
import { FC } from 'react';
import { DataCells, LoadingRow, NoDataRow } from '../common';
import { TableRow } from '../types';

type TableBodyProps = {
  columnCount: number;
  rows: TableRow[];
  isLoading?: boolean;
};

export const TableBody: FC<TableBodyProps> = ({
  columnCount,
  rows,
  isLoading = false,
}) => {
  return (
    <MuiTableBody>
      {isLoading ? (
        <LoadingRow columnCount={columnCount} />
      ) : rows.length === 0 ? (
        <NoDataRow columnCount={columnCount} />
      ) : (
        renderDataRows()
      )}
    </MuiTableBody>
  );

  function renderDataRows() {
    return rows.map((row, rowIndex) => {
      return (
        <MuiTableRow key={row.id ?? rowIndex} onClick={row.onClick}>
          <DataCells elements={row.elements} />
        </MuiTableRow>
      );
    });
  }
};
