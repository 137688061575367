import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LogoutIcon } from '../../assets/logout.svg';
import { Avatar } from '../components/avatar/Avatar.component';
import { useCompanyStore } from '../store/company-store/useCompanyStore';
import { useSessionStore } from '../store/session-store';
import { colors } from '../theme';

const StyledUserMenu = styled('div', {
  name: 'UserMenu',
  slot: 'user-menu-root',
})({
  display: 'flex',
  alignItems: 'end',
  flexDirection: 'column',
  position: 'absolute',
  right: '28px',
  top: '20px',
  zIndex: 10,
});

const StyledBox = styled(Box, { name: 'UserMenu', slot: 'user-menu' })({
  position: 'relative',
  backgroundColor: `${colors.main.background}`,
  borderRadius: '24px',
  color: `${colors.font.bright}`,
  lineHeight: '19px',
  marginTop: '12px',
});

const StyledListItemIcon = styled(ListItemIcon, {
  name: 'ListItem',
  slot: 'list-item',
})({
  minWidth: '0',
  paddingRight: '18px',
  cursor: 'pointer',
});

const StyledListItemButton = styled(ListItemButton, {
  name: 'ListItemButton',
  slot: 'list-item-button',
})({
  height: '48px',
  padding: '15px 26px 15px 26px',
  borderRadius: '24px',
  cursor: 'pointer',
  background: colors.primary,
});

export const UserMenu: FC = () => {
  const ref = useRef<HTMLInputElement>(null);
  const { logOut } = useSessionStore();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const { reset: resetCompanyStore } = useCompanyStore();

  const handleLogout = async () => {
    await logOut();
    resetCompanyStore();
    navigate('/');
  };
  const toggleMenuOpen = () => setMenuOpen(!menuOpen);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (menuOpen && !ref.current?.contains(e?.target as Node)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener('mouseup', checkIfClickedOutside);
    return () => document.removeEventListener('mouseup', checkIfClickedOutside);
  }, [menuOpen]);

  return (
    <StyledUserMenu ref={ref}>
      <Avatar toggleMenuOpen={toggleMenuOpen} />
      {menuOpen && (
        <StyledBox>
          <List sx={{ padding: '4px' }}>
            <ListItem disablePadding>
              <StyledListItemButton disableRipple onClick={handleLogout}>
                <StyledListItemIcon>
                  <LogoutIcon />
                </StyledListItemIcon>
                <ListItemText primary="Logout" />
              </StyledListItemButton>
            </ListItem>
          </List>
        </StyledBox>
      )}
    </StyledUserMenu>
  );
};
