/* eslint-disable @typescript-eslint/no-unsafe-assignment*/
import React from 'react';
import { BasicDateProps, ValidationRules } from './types';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';

type DateFormFieldProps = {
  rules?: ValidationRules;
} & BasicDateProps;

const DateFormField: React.FC<DateFormFieldProps> = ({
  control,
  controlName,
  rules,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      rules={rules}
      name={controlName}
      render={({ field }) => {
        return <DatePicker format={'dd.MM.yyyy'} {...field} {...rest} />;
      }}
    />
  );
};

export default DateFormField;
