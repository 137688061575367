import {
  Box,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, ReactNode } from 'react';

export const StyledTableContainer = styled(Paper, {
  name: 'Table',
  slot: 'root',
})(() => ({
  borderRadius: '24px',
  boxShadow: 'none',
}));

export const StyledHeader = styled(MuiTableRow, {
  name: 'Table',
  slot: 'header',
})(() => ({
  height: '72px',
  backgroundColor: 'rgba(65, 73, 77, 0.24)',
}));

export const StyledCell: FC<{
  row: ReactNode;
  index: number;
}> = ({ row, index }) => {
  return (
    <MuiTableCell
      sx={{
        paddingLeft: index === 0 ? '36px' : '16px',
        paddingRight: index != 0 ? '36px' : '16px',
      }}
    >
      {row}
    </MuiTableCell>
  );
};

export const StyledHeaderCell: FC<{
  header: ReactNode;
  index: number;
  headerCount: number;
}> = ({ header, index, headerCount }) => {
  return (
    <MuiTableCell
      sx={{
        fontWeight: 800,
        paddingLeft: index === 0 ? '36px' : '16px',
        paddingRight: index === headerCount - 1 ? '36px' : '16px',
      }}
    >
      {header}
    </MuiTableCell>
  );
};

export const StyledFullRowCell = styled(MuiTableCell, {
  name: 'Table',
  slot: 'full-row-cell',
})(() => ({
  width: '100%',
  colspan: 6,
  textAlign: 'center',
}));

export const StyledRowAction = styled(Box, {
  name: 'Table',
  slot: 'row-action',
})({
  width: 32,
  height: 32,
  borderRadius: '50%', // Makes the box a circle
  backgroundColor: 'white',
  border: '1px solid #41494D',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
