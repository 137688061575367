import { DateTime } from 'luxon';

export const formatSupabaseDateString = (
  dateString: string,
  localeStringFormat: Intl.DateTimeFormatOptions = DateTime.DATE_MED
) => {
  return dateString
    ? DateTime.fromISO(dateString).toLocaleString(localeStringFormat)
    : '';
};
