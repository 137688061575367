import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Component, ReactNode } from 'react';
import { ErrorStore, useErrorStore } from '../../store/error.store';

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
  errorInfo: string | null;
  componentError: string | undefined;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      errorInfo: null,
      componentError: useErrorStore.getState().error,
    };
  }

  static getDerivedStateFromError(error: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    return { hasError: true, errorInfo: error?.message ?? 'An error occurred' };
  }

  componentDidMount(): void {
    useErrorStore.subscribe((state: ErrorStore) => {
      this.setState({ componentError: state.error });
    });
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    console.error('Error caught by Error Boundary: ', error, errorInfo);
  }

  handleClose = () => {
    this.setState({ hasError: false, errorInfo: null });
    useErrorStore.getState().clearError();
  };

  render() {
    return (
      <>
        <Dialog
          open={this.state.hasError || this.state.componentError !== undefined}
          onClose={this.handleClose}
        >
          <DialogTitle>Something went wrong</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.state.hasError
                ? this.state.errorInfo
                : this.state.componentError}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {this.props.children}
      </>
    );
  }
}

export default ErrorBoundary;
