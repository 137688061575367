import { companyStore, CompanyStore } from './company-store';

export const useCompanyStore = (): CompanyStore => {
  const store = companyStore();

  if (!store.companies && store.isLoading === false) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    store.refresh();
  }

  return store;
};
