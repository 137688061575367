import {
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
} from '@mui/material/';
import { FC } from 'react';
import { dimensions } from '../../theme';

type DialogTitleProps = MuiDialogTitleProps;

export const DialogTitle: FC<DialogTitleProps> = ({ children, ...rest }) => {
  const padding = dimensions.dialog.padding;

  return (
    <MuiDialogTitle
      {...rest}
      sx={{
        paddingX: `${padding.X}`,
        paddingTop: `${padding.Y}`,
      }}
    >
      {children}
    </MuiDialogTitle>
  );
};
