import { ContentItemDto } from './content-item.dto';
import {
  ContentItemFormValues,
  getVisibilityFromFormValues,
} from '../../../models/content-item-form-values';
import _ from 'lodash';
import { TranslatedContentItemDto } from './translated-content-item.dto';

type UpdateFields = Omit<ContentItemDto, 'id' | 'translations'>;

export class ContentItemUpdateDto {
  updateFields?: Partial<UpdateFields>;

  id: string;

  translations?: TranslatedContentItemDto[];

  constructor(
    formValues: Partial<ContentItemFormValues>,
    id: string,
    translations?: TranslatedContentItemDto[]
  ) {
    this.updateFields = this.transformFormValuesToUpdateFields(formValues);
    this.id = id;
    this.translations = translations;
  }

  private transformFormValuesToUpdateFields(
    formValues: Partial<ContentItemFormValues>
  ): Partial<UpdateFields> {
    const cleanedValues = this.cleanFormValues(formValues);
    return {
      ...cleanedValues,
      ...getVisibilityFromFormValues(formValues),
      validUntil: cleanedValues.validUntil?.toISO() ?? undefined,
      validFrom: cleanedValues.validFrom?.toISO() ?? undefined,
    };
  }

  private cleanFormValues(
    formValues: Partial<ContentItemFormValues>
  ): Partial<ContentItemFormValues> {
    return _.omit(formValues, ['label', 'id', 'visibility']);
  }
}
