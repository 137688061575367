import {
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps,
} from '@mui/material/';
import { FC } from 'react';
import { dimensions } from '../../theme/dimensions';

type DialogActionsProps = MuiDialogActionsProps;

export const DialogActions: FC<DialogActionsProps> = ({
  children,
  ...rest
}) => {
  const padding = dimensions.dialog.padding;

  return (
    <MuiDialogActions
      {...rest}
      disableSpacing
      sx={{
        paddingX: `${padding.X}`,
        paddingY: `${padding.Y}`,

        '& button': {
          marginLeft: '16px',
        },
      }}
    >
      {children}
    </MuiDialogActions>
  );
};
