import { Stack, styled } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { colors, dimensions } from '../theme';
import { Header } from './Header.component';
import { SideMenu } from './SideMenu.component';

const Root = styled('div', { name: 'Layout', slot: 'root' })({
  flex: '1 1 auto',
  display: 'flex',
  overflow: 'auto',
  height: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
});

const Main = styled(Stack, {
  name: 'Layout',
  slot: 'main',
})(() => ({
  width: `calc(100% - ${dimensions.sidebar.width})`,
  height: '100%',
  backgroundColor: `${colors.main.background}`,
}));

const Content = styled(Stack, {
  name: 'Layout',
  slot: 'content',
})(() => ({
  width: '100%',
  height: '100%',
  overflowY: 'auto',
}));

export const Layout = () => {
  return (
    <Root>
      <SideMenu />
      <Main>
        <Header />
        <Content>
          <Outlet />
        </Content>
      </Main>
    </Root>
  );
};
