import { ContentItemVisibility } from '../../../../core/models/content-item-visibility';

export const visibilityFormFieldOptions: {
  value: string;
  title: string;
  description: string;
}[] = [
  {
    value: ContentItemVisibility.ALWAYS_VISIBLE.toString(),
    title: 'Always visible',
    description:
      'If this option is activated, this link is always shown to the users and cannot be hidden by them.',
  },
  {
    value: ContentItemVisibility.VISIBLE_BY_DEFAULT.toString(),
    title: 'Visible by default',
    description:
      'If this option is activated, the link is shown to users by default. Users can then decide whether they want to show or hide the link.',
  },
  {
    value: ContentItemVisibility.HIDDEN_BY_DEFAULT.toString(),
    title: 'Hidden by default',
    description:
      'If this option is activated, the link is hidden by default. Users can then decide whether they want to show the link or keep it hidden.',
  },
];
