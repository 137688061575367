import React from 'react';
import { Box, styled } from '@mui/material';
import { ReactComponent as CrossContentSvg } from '../../../../../assets/cross_content_icon.svg';

interface IContentItemTableIconProps {
  iconUrl?: string;
}

const ContentItemTableIconContainer = styled(Box)`
  border-radius: 20px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.default};

  & svg {
    width: 80%;
    height: 80%;
  }
`;

const ContentItemTableIcon: React.FC<IContentItemTableIconProps> = ({
  iconUrl,
}) => {
  return (
    <ContentItemTableIconContainer>
      {iconUrl ? (
        <img
          src={iconUrl}
          alt={'icon'}
          style={{ width: '50px', height: '50px', objectFit: 'cover' }}
        />
      ) : (
        <CrossContentSvg />
      )}
    </ContentItemTableIconContainer>
  );
};

export default ContentItemTableIcon;
