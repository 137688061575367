/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react';
import { useSessionStore } from '../store/session-store';

export const useStateInitialization = (): void => {
  const { refreshAuthentication } = useSessionStore();

  useEffect(() => {
    refreshAuthentication();
  }, []);
};
