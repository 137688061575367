import { FC } from 'react';
import { TextFormField } from './TextFormField.component';
import { BasicFormProps, ValidationRules } from './types';

type EmailFormFieldProps = Omit<BasicFormProps, 'rules'>;

export const EmailFormField: FC<EmailFormFieldProps> = ({
  control,
  ...rest
}) => {
  const props = { ...rest, type: 'email' };

  const emailRules: ValidationRules = {
    required: {
      value: true,
      message: 'E-mail address required',
    },
    pattern: {
      value: /^[\w\-+.]+@([\w-]+\.)+[\w-]{2,}$/,
      message: 'No valid e-mail address',
    },
  };

  return <TextFormField {...props} rules={emailRules} control={control} />;
};
