import { client } from '../api/api.client';
import {
  FeatureFlagDto,
  FeatureFlagDto as JsonFeatureFlag,
} from '../api/data/dto/feature-flag.dto';
import { FeatureFlag } from '../models/feature-flag.model';

type FeatureFlagRepository = {
  getFeatureFlags: () => Promise<FeatureFlag[]>;
  toggleFeatureFlag: (featureFlag: FeatureFlag) => Promise<FeatureFlag>;
};

const repository = (): FeatureFlagRepository => {
  return {
    getFeatureFlags: async (): Promise<FeatureFlag[]> => {
      const response: JsonFeatureFlag[] = await client.getFeatureFlags();

      const featureFlags: FeatureFlag[] = response.map((flag) =>
        FeatureFlag.fromJson(flag)
      );

      return featureFlags;
    },

    toggleFeatureFlag: async (
      featureFlag: FeatureFlag
    ): Promise<FeatureFlag> => {
      const clientFeatureFlag: FeatureFlagDto = await client.toggleFeatureFlag(
        FeatureFlag.toJson(featureFlag) as FeatureFlagDto
      );

      return FeatureFlag.fromJson(clientFeatureFlag);
    },
  };
};

export const featureFlagRepository = repository();
