import { create } from 'zustand';
import { Company } from '../../models/company.model';
import { companyRepository } from '../../repository';

type State = {
  isLoading: boolean;
  companies?: Company[];
  selectedCompany?: Company;
};

type Actions = {
  refresh: () => Promise<void>;
  setSelectedCompany: (companyId: string) => void;

  reset: () => void;
};

export type CompanyStore = State & Actions;

const initialState = {
  isLoading: false,
  companies: undefined,
  selectedCompany: undefined,
};

/**
 * This store has currently two functions:
 *  - it allows the inform admin to select the correct company within the app
 *  - the selected company id is read at API/client level to perform proper API calls using the selected company id
 *    TODO: remove later and pass companyId to the API calls
 *
 *  Note: if the logged in user is just a company admin the selected company id is always the one of the company admin
 */

export const companyStore = create<CompanyStore>((set) => ({
  ...initialState,

  setSelectedCompany: (companyId: string) => {
    set((state) => {
      const company = state.companies?.find((c) => c.id === companyId);
      return { selectedCompany: company };
    });
  },

  refresh: async () => {
    set({ isLoading: true });
    try {
      const companies = await companyRepository.getCompanies();
      const selectedCompany = companies[0];

      set({
        companies,
        selectedCompany,
        isLoading: false,
      });
    } catch (e) {
      console.error(e);
      set({ isLoading: false });
    }
  },

  reset: () => {
    set(initialState);
  },
}));
