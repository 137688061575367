import { create } from 'zustand';
import { ContentItem } from '../models/content-item.model';

interface ContentItemStore {
  contentItems: ContentItem[];
  loading: boolean;
  setContentItems: (contentItems: ContentItem[]) => void;
  setLoading: (loading: boolean) => void;
  getWithId: (id: string) => ContentItem | undefined;
}

export const useContentItemStore = create<ContentItemStore>((set, get) => ({
  contentItems: [],
  loading: true,
  setContentItems: (contentItems) => {
    set({ contentItems, loading: false });
  },
  setLoading: (loading) => {
    set({ loading });
  },
  getWithId: (id) => {
    return get().contentItems.find((item) => item.id === id);
  },
}));
