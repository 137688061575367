/* eslint-disable @typescript-eslint/lines-between-class-members, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/dot-notation */

import { DateTime } from 'luxon';
import { UUID } from './typedefs';

export class User {
  id?: UUID;
  email: string;
  password?: string;
  avatar?: string;
  userName?: string;
  firstName?: string;
  lastName?: string;
  lastLoggedIn?: DateTime;
  foreignSystemId?: string;
  acceptedTosAt?: DateTime;
  companyId?: string;

  constructor({
    id,
    email,
    password,
    avatar,
    userName,
    firstName,
    lastName,
    lastLoggedIn,
    foreignSystemId,
    acceptedTosAt,
    companyId,
  }: {
    id?: UUID;
    email: string;
    password?: string;
    avatar?: string;
    userName?: string;
    firstName?: string;
    lastName?: string;
    lastLoggedIn?: DateTime;
    foreignSystemId?: string;
    acceptedTosAt?: DateTime;
    companyId?: string;
  }) {
    this.id = id;
    this.email = email;
    this.password = password;
    this.avatar = avatar;
    this.userName = userName;
    this.firstName = firstName;
    this.lastName = lastName;
    this.lastLoggedIn = lastLoggedIn;
    this.foreignSystemId = foreignSystemId;
    this.acceptedTosAt = acceptedTosAt;
    this.companyId = companyId;
  }

  static fromJson(json: { [x: string]: any }) {
    return new User({
      id: json['id'],
      email: json['email'],
      avatar: json['avatar'],
      userName: json['user_name'],
      firstName: json['first_name'],
      lastName: json['last_name'],
      lastLoggedIn: json['last_sign_in_at'],
      foreignSystemId: json['foreign_system_id'],
      acceptedTosAt: json['accepted_tos_at'],
      companyId: json['company_id'],
    });
  }

  toJson(): {
    [x: string]: any;
  } {
    return {
      id: this.id ?? null,
      email: this.email,
      password:
        // we need some special handling here because the password is optional and the default value is ""
        this.password?.length !== undefined && this.password?.length > 0
          ? this.password
          : null,
      user_name: this.userName,
      first_name: this.firstName,
      last_name: this.lastName,
    };
  }
}
