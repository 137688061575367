import { client } from '../api.client';

export const uploadFileToStorage = async (
  file: File,
  fileName: string,
  bucket: string
): Promise<{ path: string }> => {
  return client.uploadFile(fileName, file, bucket, { replace: false });
};

export const chooseFileFromLocalMachine = (
  limitOfFiles: number | null,
  fileType = 'image/*'
) => {
  return new Promise<FileList | null>((resolve) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = limitOfFiles !== 1;
    input.accept = fileType;
    input.onchange = () => {
      if (input.files) resolve(input.files);
    };
    input.click();

    input.oncancel = () => {
      resolve(null);
    };
  });
};
