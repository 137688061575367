import { Stack } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';

import { Avatar } from '../../core/components/avatar/Avatar.component';
import { Button } from '../../core/components/button';
import { PasswordConfirmationDialog } from '../../core/components/confirmation-dialog/PasswordConfirmationDialog';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../core/components/dialog';
import { EmailFormField } from '../../core/components/form/EmailFormField.component';
import { PasswordFormFieldWithHint } from '../../core/components/form/PasswordFormField';
import { TextFormField } from '../../core/components/form/TextFormField.component';
import { useForm } from '../../core/hooks/useForm';
import { Admin } from '../../core/models/admin.model';
import { UUID } from '../../core/models/typedefs';
import { User } from '../../core/models/user.model';
import { useUserRepository } from '../../core/repository/useUserRepository';
import { useAdminByEmail } from '../../core/store/admin-store';
import { useThrowError } from '../../core/store/error.store';
import { useSessionStore } from '../../core/store/session-store';

type FormValues = {
  id?: UUID;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  password: string;
  [key: string]: string | UUID | undefined;
};

type UserDialogProps = {
  onClose: (user?: User) => void;
  selectedUser?: User;
};

export const UserDialog: FC<UserDialogProps> = ({ selectedUser, onClose }) => {
  //region STATE
  const userRepository = useUserRepository();
  const selfUserId = useSessionStore((state) => state.selfUser.id);
  const throwError = useThrowError();

  const [profilePicture, setProfilePicture] = useState<Blob | null>(null);
  const [showPasswordPolicyHint, setShowPasswordPolicyHint] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    formValues,
    hasChanged: formHasChanged,
    reset: resetForm,
  } = useForm<FormValues>({
    defaultValues: useMemo(() => {
      return getDefaultValues(selectedUser);
    }, [selectedUser]),
  });
  const admin: Admin | undefined = useAdminByEmail(formValues.email);
  const isAdminUser = !!admin;

  const isNewUser = !selectedUser?.id;
  const needPasswordConfirmation =
    (selfUserId === selectedUser?.id || isAdminUser) &&
    formValues.password.length > 0;
  //endregion

  //region LIFECYCLE
  useEffect(() => {
    resetForm(getDefaultValues(selectedUser));
    if (isNewUser) {
      setProfilePicture(null);
    } else {
      userRepository
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .getProfilePicture(selectedUser.id!)
        .then((picture) => {
          setProfilePicture(picture);
        })
        .catch((err) => {
          throw err;
        });
    }
  }, [selectedUser]);
  //endregion

  //region HANDLER
  const submit = () => {
    if (needPasswordConfirmation) {
      setShowConfirmDialog(true);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      syncUser();
    }
  };

  const passwordConfirm = (confirmationPassword: string) => {
    setShowConfirmDialog(false);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    syncUser(confirmationPassword);
  };

  const syncUser = async (confirmationPassword?: string) => {
    const formUser: User = new User({
      ...formValues,
    });

    setIsLoading(true);
    let response: User;
    try {
      if (isNewUser && !isAdminUser) {
        response = await userRepository.add(formUser);
      } else {
        formUser.id = selectedUser?.id ?? admin?.id;
        response = await userRepository.update(formUser, confirmationPassword);
      }
      onClose(response);
    } catch (e) {
      throwError(e);
      onClose(undefined);
    }
  };
  //endregion

  return (
    <>
      {showConfirmDialog && (
        <PasswordConfirmationDialog
          open={showConfirmDialog}
          description="Please enter your password to confirm the changes."
          onClose={onClose}
          onConfirm={passwordConfirm}
        />
      )}

      <Dialog fullWidth maxWidth={'sm'} open={true}>
        <DialogTitle>
          <Stack direction={'row'} alignItems={'center'} spacing={'24px'}>
            <Avatar user={selectedUser} profilePicture={profilePicture} />
            <span>
              {formValues.firstName} {formValues.lastName}
            </span>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <Stack
            alignItems={'center'}
            sx={{ minHeight: '368px', minWidth: 400 }}
          >
            <form style={{ width: '100%' }} onSubmit={submit} noValidate>
              <Stack sx={{ width: '100%' }} spacing={'36px'}>
                <Stack direction={'row'} spacing={'37px'}>
                  <TextFormField
                    disabled={isLoading}
                    control={control}
                    controlName="firstName"
                    label="First name"
                    rules={{ maxLength: 10 }}
                  ></TextFormField>
                  <TextFormField
                    disabled={isLoading}
                    control={control}
                    controlName="lastName"
                    label="Last name"
                  ></TextFormField>
                </Stack>
                <TextFormField
                  disabled={isLoading}
                  control={control}
                  controlName="userName"
                  label={'Username'}
                ></TextFormField>
                <EmailFormField
                  disabled={isLoading}
                  control={control}
                  controlName="email"
                  label="E-Mail"
                />
                <PasswordFormFieldWithHint
                  disabled={isLoading}
                  value={formValues.password}
                  control={control}
                  show={showPasswordPolicyHint}
                  required={isNewUser}
                  onFocus={() => setShowPasswordPolicyHint(true)}
                />
              </Stack>
            </form>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            loading={isLoading}
            disabled={!formHasChanged || isLoading}
            onClick={submit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const getDefaultValues = (user?: User): FormValues => {
  return {
    id: user?.id,
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    userName: user?.userName ?? '',
    email: user?.email ?? '',
    password: user?.password ?? '',
  };
};
