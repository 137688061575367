import { Stack, styled, Typography } from '@mui/material';
import { FC, ReactNode, useEffect, useState } from 'react';
import { ReactComponent as EditSvg } from '../../assets/edit.svg';
import { ReactComponent as RemoveSvg } from '../../assets/remove.svg';

import { RowAction, Table, TableRow } from '../../core/components/table';
import { Admin } from '../../core/models/admin.model';
import { useSessionStore } from '../../core/store/session-store';

const StyledRowTypography = styled(Typography, {
  name: 'AdminTable',
  slot: 'row',
})({
  fontSize: '16px',
});

export interface AdminTableProps {
  admins: Admin[] | undefined;
  onEdit: (admin: Admin) => void;
  onRemove: (admin: Admin) => void;
}

export const AdminTable: FC<AdminTableProps> = ({
  admins,
  onEdit,
  onRemove,
}) => {
  const session = useSessionStore();
  const [rows, setRows] = useState<TableRow[]>([]);
  const headers: ReactNode[] = [
    'Name',
    'E-Mail',
    '', // Actions
  ];

  useEffect(() => {
    if (admins) {
      const tableRowsNew: TableRow[] = [];

      admins.forEach((admin) => {
        if (!admin.id) return;

        const row: TableRow = {
          id: admin.id,
          elements: [
            <StyledRowTypography key={0}>
              {admin.firstName} {admin.lastName}
            </StyledRowTypography>,
            <StyledRowTypography key={1}>{admin.email}</StyledRowTypography>,
            <StyledRowTypography key={2}>
              <Stack direction={'row'} justifyContent={'flex-end'}>
                <RowAction onClick={() => onEdit(admin)}>
                  <EditSvg />
                </RowAction>

                {admin.id !== session.selfUser.id && (
                  <RowAction onClick={() => onRemove(admin)}>
                    <RemoveSvg />
                  </RowAction>
                )}
              </Stack>
            </StyledRowTypography>,
          ],
        };

        if (admin.id === session.selfUser.id) {
          tableRowsNew.unshift(row);
        } else {
          tableRowsNew.push(row);
        }
      });

      setRows(tableRowsNew);
    }
  }, [admins, setRows, onEdit, onRemove]);

  // we do not need pagination for the admin table because there are at most 2 admins
  return <Table headers={headers} rows={rows} usePagination={false} />;
};
