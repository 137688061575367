export const colors = {
  primary: '#41494d',
  secondary: '#FFD359',
  error: '#b3261e',
  bright: '#fff',
  dark: '#000',
  grey: '#808080',
  font: {
    get bright() {
      return colors.bright;
    },
    get dark() {
      return colors.dark;
    },
  },
  main: {
    background: '#dcdcdc',
  },
  sidebar: {
    get background() {
      return colors.primary;
    },
  },
  dialog: {
    get background() {
      return colors.bright;
    },
  },
  button: {
    font: {
      get bright() {
        return colors.bright;
      },
      dark: '#41494d',
    },
  },
};
