import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
} from '@mui/material/';
import { FC } from 'react';
import { colors } from '../../theme';
import { hexToRgbA } from '../../theme/utils';

type DialogProps = MuiDialogProps;

export const Dialog: FC<DialogProps> = ({ children, ...rest }) => {
  const handleClose = (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason !== 'backdropClick' && rest.onClose) {
      rest?.onClose(event, reason);
    }
  };

  return (
    <MuiDialog
      {...rest}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '24px',
          boxShadow: 'none',
        },
        '& .MuiModal-backdrop': {
          backgroundColor: hexToRgbA(colors.dark, 0.3),
        },
      }}
    >
      {children}
    </MuiDialog>
  );
};
