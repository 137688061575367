import { client } from '../api/api.client';
import { JSONArray, JSONValue } from '../api/types';
import { Admin } from '../models/admin.model';

type AdminRepository = {
  getAdmins: () => Promise<Admin[]>;
  addAdmin: (admin: Admin, currentPassword?: string) => Promise<Admin>;
  updateAdmin: (admin: Admin, currentPassword?: string) => Promise<Admin>;
  removeAdmin: (adminId: string, currentPassword?: string) => Promise<void>;
};

const repository = (): AdminRepository => {
  return {
    getAdmins: async (): Promise<Admin[]> => {
      const adminsJson: JSONArray = await client.getAdmins();
      const admins = adminsJson.map((admin: JSONValue) =>
        Admin.fromJson(admin as { [x: string]: any })
      );

      return admins;
    },

    addAdmin: async (
      admin: Admin,
      currentPassword?: string
    ): Promise<Admin> => {
      const newAdmin = await client.addAdmin(admin.toJson(), currentPassword);
      return Admin.fromJson(newAdmin);
    },

    updateAdmin: async (
      admin: Admin,
      currentPassword?: string
    ): Promise<Admin> => {
      const updateAdmin = await client.updateAdmin(
        admin.toJson(),
        currentPassword
      );
      return Admin.fromJson(updateAdmin);
    },

    removeAdmin: async (
      adminId: string,
      currentPassword?: string
    ): Promise<void> => {
      if (!adminId) return;

      await client.removeAdmin(adminId, currentPassword);
    },
  };
};

export const adminRepository = repository();
