import { create } from 'zustand';
import { Admin } from '../../models/admin.model';
import { adminRepository } from '../../repository';

type State = {
  isLoading: boolean;
  admins?: Admin[];
};

type Actions = {
  refresh: () => Promise<void>;
  addAdmin: (admin: Admin) => Promise<Admin>;
  updateAdmin: (admin: Admin, currentPassword: string) => Promise<Admin>;
  removeAdmin: (adminId: string, currentPassword: string) => Promise<void>;
};

export type AdminStore = State & Actions;

const initialState = {
  isLoading: false,
  admins: undefined,
};

export const adminStore = create<AdminStore>((set) => ({
  ...initialState,

  refresh: async () => {
    set({ isLoading: true });
    try {
      const admins = await adminRepository.getAdmins();
      set({ admins, isLoading: false });
    } catch (e) {
      console.error(e);
      set({ isLoading: false });
    }
  },

  addAdmin: async (admin: Admin): Promise<Admin> => {
    const newAdmin = await adminRepository.addAdmin(admin);
    return newAdmin;
  },

  updateAdmin: async (
    admin: Admin,
    currentPassword: string
  ): Promise<Admin> => {
    const updatedAdmin = await adminRepository.updateAdmin(
      admin,
      currentPassword
    );
    return updatedAdmin;
  },

  removeAdmin: async (
    adminId: string,
    currentPassword: string
  ): Promise<void> => {
    await adminRepository.removeAdmin(adminId, currentPassword);
  },
}));
