import {
  DialogContent as MuiDialogContent,
  DialogContentProps as MuiDialogContentProps,
} from '@mui/material/';
import { FC } from 'react';
import { dimensions } from '../../theme/dimensions';

type DialogContentProps = MuiDialogContentProps;

export const DialogContent: FC<DialogContentProps> = ({
  children,
  ...rest
}) => {
  const padding = dimensions.dialog.padding;

  return (
    <MuiDialogContent
      {...rest}
      sx={{
        paddingX: `${padding.X}`,
      }}
      style={{
        // this is a workaround because the sx property would get overriden
        // as soon as we use the dialog title component
        paddingTop: `${padding.Y}`,
      }}
    >
      {children}
    </MuiDialogContent>
  );
};
