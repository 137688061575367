export const dimensions = {
  main: {
    padding: {
      X: `24px`,
    },
  },
  header: {
    height: `80px`,
    padding: {
      X: `24px`,
      Y: `20px`,
    },
    margin: {
      bottom: `16px`,
    },
  },
  sidebar: {
    width: `240px`,
    padding: {
      X: `16px`,
      Y: `24px`,
    },
  },
  dialog: {
    padding: {
      top: `46px`,
      bottom: `24px`,
      X: `42px`,
      Y: `42px`,
    },
  },
  button: {
    padding: {
      X: `24px`,
    },
    height: `40px`,
  },
};
