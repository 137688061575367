import {
  ContentItemVisibility,
  getVisibility,
} from './content-item-visibility';
import { ContentItem } from './content-item.model';
import { UUID } from './typedefs';
import { DateTime } from 'luxon';

export type ContentItemFormValues = {
  id?: UUID;
  icon?: string;
  imagePath?: string;
  label: string;
  link: string;
  validFrom?: DateTime | null;
  validUntil?: DateTime | null;
  visibility: ContentItemVisibility;
  openInEmbeddedBrowser?: boolean;
  description?: string;
  defaultLocale?: number;
};

export const getVisibilityFromFormValues = (
  formValues: Partial<ContentItemFormValues>
) => {
  if (formValues.visibility)
    return {
      userCanHideItem: getUserCanHideItemFromForm(formValues.visibility),
      defaultShowInApp: getDefaultShowInAppFromForm(formValues.visibility),
    };
};
const getUserCanHideItemFromForm = (visibility: ContentItemVisibility) => {
  return visibility != ContentItemVisibility.ALWAYS_VISIBLE;
};
const getDefaultShowInAppFromForm = (visibility: ContentItemVisibility) => {
  return (
    visibility == ContentItemVisibility.VISIBLE_BY_DEFAULT ||
    visibility == ContentItemVisibility.ALWAYS_VISIBLE
  );
};

export const fromContentItem = (
  contentItem: ContentItem
): ContentItemFormValues => {
  return {
    id: contentItem.id,
    icon: contentItem.imageUrl,
    label: contentItem.title,
    link: contentItem.link,
    validFrom: contentItem.validFrom
      ? DateTime.fromISO(contentItem.validFrom)
      : undefined,
    validUntil: contentItem.validUntil
      ? DateTime.fromISO(contentItem.validUntil)
      : undefined,
    visibility: getVisibility(contentItem),
    openInEmbeddedBrowser: contentItem.showAsInAppBrowser,
    description: contentItem.description ?? '',
    defaultLocale: contentItem.defaultLocaleId,
  };
};

export const getDefaultValues = (
  contentItem?: ContentItem
): ContentItemFormValues => {
  return {
    id: contentItem?.id,
    icon: contentItem?.imageUrl,
    label: contentItem?.title || '',
    link: contentItem?.link || '',
    validFrom:
      contentItem?.validFrom != null
        ? DateTime.fromISO(contentItem?.validFrom)
        : null,
    validUntil:
      contentItem?.validUntil != null
        ? DateTime.fromISO(contentItem?.validUntil)
        : null,
    visibility: getVisibility(contentItem),
    defaultLocale: 1,
    openInEmbeddedBrowser: contentItem?.showAsInAppBrowser || false,
    description: contentItem?.description ?? '',
  };
};
