import { instanceToPlain, plainToInstance } from 'class-transformer';
import { JSONArray, JSONObject } from '../api/types';
import { ContentItemTranslationModel } from './content-item-translation.model';

export class ContentItem {
  id: string;

  imageUrl?: string;

  link: string;

  position: number;

  showAsInAppBrowser: boolean;

  title: string;

  userCanHideItem: boolean;

  defaultShownInApp: boolean;

  validFrom: string;

  validUntil: string;

  availableTranslations: ContentItemTranslationModel[];

  defaultLocaleId: number;

  description?: string;

  constructor(
    id: string,
    link: string,
    position: number,
    showAsInAppBrowser: boolean,
    title: string,
    userCanHideItem: boolean,
    validFrom: string,
    validUntil: string,
    availableTranslations: ContentItemTranslationModel[],
    defaultLocaleId: number,
    description = '',
    defaultShownInApp = false,
    imageUrl = ''
  ) {
    this.id = id;
    this.link = link;
    this.position = position;
    this.showAsInAppBrowser = showAsInAppBrowser;
    this.title = title;
    this.userCanHideItem = userCanHideItem;
    this.validFrom = validFrom;
    this.validUntil = validUntil;
    this.availableTranslations = availableTranslations;
    this.defaultLocaleId = defaultLocaleId;
    this.description = description;
    this.defaultShownInApp = defaultShownInApp;
    this.imageUrl = imageUrl;
  }

  static fromJson(jsonMap: JSONObject) {
    return plainToInstance(ContentItem, jsonMap);
  }

  static fromJsonArray(jsonArray: JSONArray) {
    return plainToInstance(ContentItem, jsonArray);
  }

  toJson() {
    return instanceToPlain(this);
  }
}
