/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { UUID } from './typedefs';

export class Company {
  id: UUID;

  name: string;

  key: string;

  constructor({ id, name, key }: { id: UUID; name: string; key: string }) {
    this.id = id;
    this.name = name;
    this.key = key;
  }

  static fromJson(json: { [x: string]: any }): Company {
    return new Company({
      id: json.id,
      name: json.name,
      key: json.key,
    });
  }
}
