import { Alert, Box, Snackbar, Stack, styled, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as FrappLogoWithText } from '../../assets/frapp_logo_with_text.svg';
import { AuthApiError } from '../../core/api/api-errors';
import { routes } from '../../core/navigation/routes';
import { useThrowError } from '../../core/store/error.store';
import { useSessionStore } from '../../core/store/session-store';
import { colors } from '../../core/theme';

const StyledBox = styled(Box, { name: 'Login', slot: 'login' })({
  display: 'flex',
  maxWidth: '560px',
  background: `${colors.dialog.background}`,
  padding: '64px 130px 40px 130px',
  borderRadius: 12,
});

const StyledTextField = styled(TextField, { name: 'Login', slot: 'text' })({
  minWidth: '100%',
  // To prevent the border to overlap with the label on active, we need to set the border color via class
  '& .MuiOutlinedInput-root': {
    '& > fieldset': { borderColor: '#70454d' },
  },
});

const StyledButton = styled(Button, { name: 'Login', slot: 'button' })({
  background: '#7B4B54',
  borderRadius: 100,
  minWidth: '310px',
  textTransform: 'none',
  padding: '14px 0px 14px 0px',
  fontSize: '16px',
  lineHeight: '20px',
  '&:hover': {
    background: '#70454d',
  },
});

export const Login = () => {
  const [companyKey, setCompanyKey] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { logIn } = useSessionStore();
  const [authError, setAuthError] = useState('');
  const throwError = useThrowError();

  const navigate = useNavigate();

  const handleLogin = async (event: React.SyntheticEvent): Promise<void> => {
    event.preventDefault();
    try {
      setAuthError('');
      await logIn(email, password, companyKey);
      navigate(routes.users);
    } catch (e) {
      if (e instanceof AuthApiError) {
        setAuthError(e.message);
      } else {
        throwError(e);
      }
    }
  };

  return (
    <Stack alignItems={'center'}>
      <StyledBox justifyContent={'center'}>
        <Stack alignItems={'center'} sx={{ maxWidth: '300px' }}>
          <FrappLogoWithText />
          <Typography
            sx={{
              textTransform: 'uppercase',
              color: colors.primary,
              fontWeight: 700,
              fontSize: 20,
              padding: '48px 0px',
              lineHeight: '24px',
            }}
          >
            Administration
          </Typography>
          <StyledTextField
            label="Company ID"
            variant="outlined"
            value={companyKey}
            onChange={(e) => setCompanyKey(e.target.value)}
            InputProps={{ sx: { borderRadius: '12px' } }}
            sx={{
              marginBottom: '48px',
            }}
          />
          <StyledTextField
            label="Username"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{ sx: { borderRadius: '12px' } }}
            sx={{
              marginBottom: '24px',
            }}
          />
          <StyledTextField
            type="password"
            label="Password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{ sx: { borderRadius: '12px' } }}
          />
          <StyledButton
            onClick={handleLogin}
            disableRipple
            disableElevation
            type="submit"
            variant="contained"
            sx={{ marginTop: '48px' }}
          >
            Login
          </StyledButton>
        </Stack>
      </StyledBox>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={authError.length > 0}
        autoHideDuration={5000}
        onClose={() => setAuthError('')}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {authError}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
