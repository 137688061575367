/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FormControlLabel } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Switch } from '../button';
import { BasicSwitchProps, ValidationRules } from './types';

interface ISwitchFormFieldProps extends BasicSwitchProps {
  rules?: ValidationRules;
  label: string;
}

const SwitchFormField: React.FC<ISwitchFormFieldProps> = ({
  control,
  controlName,
  rules,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={controlName}
      rules={rules}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Switch
              sx={{ m: 1 }}
              {...rest}
              {...field}
              checked={field.value}
              ref={undefined}
            />
          }
          label={rest.label}
        />
      )}
    ></Controller>
  );
};

export default SwitchFormField;
