import { create } from 'zustand';

type State = {
  error: string | undefined;
};

type Actions = {
  setError: (error: string) => void;
  clearError: () => void;
};

export type ErrorStore = State & Actions;

export const useErrorStore = create<ErrorStore>((set) => ({
  error: undefined,
  setError: (error) => set({ error }),
  clearError: () => set({ error: undefined }),
}));

export const useThrowError = () => {
  return (error: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const message: string = error?.message ?? 'An error occurred';
    useErrorStore.getState().setError(message);
  };
};
