import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material/';
import { FC } from 'react';
import { colors, dimensions } from '../../theme';
import { hexToRgbA } from '../../theme/utils';
import { CircularProgress } from '../loader/CircularProgress.component';

interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
}

const primaryStyle = {
  background: colors.primary,
  color: colors.button.font.bright,
  ':hover': {
    background: hexToRgbA(colors.primary, 0.9),
    color: colors.button.font.bright,
  },
};

const secondaryStyle = {
  borderColor: colors.primary,
  color: colors.button.font.dark,
  ':hover': {
    borderColor: colors.primary,
    background: hexToRgbA(colors.primary, 0.1),
    color: colors.button.font.dark,
  },
};

const ternaryStyle = {
  color: colors.button.font.dark,
  ':hover': {
    background: hexToRgbA(colors.primary, 0.1),
  },
};

export const Button: FC<ButtonProps> = ({
  variant = 'contained',
  loading = false,
  children,
  ...rest
}) => {
  let style;
  switch (variant) {
    case 'contained':
      style = primaryStyle;
      break;
    case 'outlined':
      style = secondaryStyle;
      break;
    case 'text':
      style = ternaryStyle;
      break;
    default:
  }

  return (
    <MuiButton
      {...rest}
      startIcon={loading ? <CircularProgress size={20} /> : rest.startIcon}
      sx={{
        borderRadius: '24px',
        textTransform: 'none',
        paddingX: `${dimensions.button.padding.X}`,
        height: `${dimensions.button.height}`,
        ':hover': {
          color: 'inherit',
          background: 'inherit',
        },
        ...style,
      }}
      variant={variant}
    >
      {children}
    </MuiButton>
  );
};
