import { Box, IconButton, TableRow as MuiTableRow } from '@mui/material';
import { FC, ReactNode } from 'react';
import { CircularProgress } from '../loader/CircularProgress.component';
import { StyledCell, StyledFullRowCell, StyledRowAction } from './styles';

export const LoadingRow: FC<{ columnCount: number }> = ({ columnCount }) => {
  return (
    <FullRow columnCount={columnCount}>
      <CircularProgress />
    </FullRow>
  );
};

export const NoDataRow: FC<{ columnCount: number }> = ({ columnCount }) => {
  return (
    <FullRow columnCount={columnCount}>
      <Box textAlign="center">No data available</Box>
    </FullRow>
  );
};

export const FullRow: FC<{
  columnCount: number;
  children: React.ReactNode;
}> = ({ children, columnCount }) => {
  return (
    <MuiTableRow>
      <StyledFullRowCell colSpan={columnCount}>{children}</StyledFullRowCell>
    </MuiTableRow>
  );
};

export const DataCells: FC<{ elements: ReactNode[] }> = ({ elements }) => {
  return (
    <>
      {elements.map((dataElement, index) => (
        <StyledCell key={index} index={index} row={dataElement} />
      ))}
    </>
  );
};

export const RowAction: FC<{ onClick: () => void; children: ReactNode }> = ({
  onClick,
  children,
}) => {
  return (
    <IconButton onClick={onClick}>
      <StyledRowAction>{children}</StyledRowAction>
    </IconButton>
  );
};
