import { createRef, FC, useEffect } from 'react';
import { Button } from '../../core/components/button';

import { MainPageLayout } from '../../core/components/layout';
import { useContentItemRepository } from '../../core/repository/useContentItemRepository';
import { useCompanyStore } from '../../core/store/company-store/useCompanyStore';
import { useContentItemStore } from '../../core/store/content-item-store';
import ContentItemDialog, {
  IContentItemActions,
} from './components/dialog/ContentItem.dialog';
import { ContentItemTableComponent } from './components/table/ContentItemTable.component';

const ContentItemManagement: FC = () => {
  //region STATE & HOOKS
  const contentItemStore = useContentItemStore();
  const contentRepository = useContentItemRepository();
  const dialogReference = createRef<IContentItemActions>();

  const { selectedCompany } = useCompanyStore();
  //endregion

  //region LIFECYCLE
  useEffect(() => {
    if (selectedCompany?.id !== undefined) {
      contentRepository
        .getContentItems()
        .then(() => console.debug('fetched content items'))
        .catch((e) => console.error(e));
    }
  }, [selectedCompany?.id]);
  //endregion

  return (
    <MainPageLayout
      action={
        <Button variant={'contained'} onClick={handleAddContentItem}>
          + Add Content Item
        </Button>
      }
      title={'Cross Content Hub'}
    >
      <ContentItemDialog ref={dialogReference} />
      <ContentItemTableComponent handleClickOnRow={handleClickOnRow} />
    </MainPageLayout>
  );

  //region HANDLER
  function handleAddContentItem() {
    dialogReference.current?.setOpen(true);
  }

  function handleClickOnRow(id: string) {
    const contentItem = contentItemStore.getWithId(id);
    if (contentItem) {
      dialogReference.current?.openWithItem(contentItem);
    }
  }

  //endregion
};
export default ContentItemManagement;
