import { create } from 'zustand';
import { Admin, AdminType } from '../models/admin.model';
import { sessionRepository } from '../repository';

type State = {
  isAuthenticated: boolean;
  selfUser: Admin;
};

type Actions = {
  setSelfUser: (authUser: Admin) => void;
  resetSelfUser: () => void;
  refreshAuthentication: () => void;

  logIn: (email: string, password: string, companyId: string) => Promise<void>;
  logOut: () => Promise<void>;
};

export type SessionStore = State & Actions;

const initialState = {
  isAuthenticated: false,
  selfUser: getDefaultUser(),
};

export const useSessionStore = create<SessionStore>((set) => ({
  ...initialState,

  setSelfUser: (authUser: Admin) => {
    set({ selfUser: authUser });
  },

  resetSelfUser: () => set({ selfUser: getDefaultUser() }),

  refreshAuthentication: async () => {
    const isAuthenticated = await sessionRepository.isAuthenticated();
    set({ isAuthenticated });
  },

  logIn: async (email: string, password: string, companyId: string) => {
    await sessionRepository.logIn(email, password, companyId);
    set({ isAuthenticated: true });
  },

  logOut: async () => {
    await sessionRepository.logOut();
    set({ isAuthenticated: false });
  },
}));

function getDefaultUser() {
  return new Admin({
    email: '',
    type: AdminType.ADMIN,
  });
}
