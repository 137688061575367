import { client } from '../api/api.client';
import { JSONArray, JSONValue } from '../api/types';
import { Admin } from '../models/admin.model';
import { UUID } from '../models/typedefs';
import { User } from '../models/user.model';

type UserRepository = {
  getSelfUser(): Promise<Admin>;
  getUser(userId: UUID): Promise<User>;
  getUsers(): Promise<User[]>;
  getUsersForRange(
    start: number,
    end: number
  ): Promise<{ users: User[]; totalCount: number }>;
  add(user: User): Promise<User>;
  update(user: User, currentPassword?: string): Promise<User>;
  getProfilePicture(pictureId: UUID): Promise<Blob | null>;
};

export const useUserRepository = (): UserRepository => {
  return {
    getSelfUser: async () => {
      const response = await client.getSelfUser();
      return Admin.fromJson(response);
    },
    getUser: async (userId: UUID) => {
      const response = await client.getUser(userId);
      return User.fromJson(response);
    },
    getUsers: async () => {
      const response: JSONArray = await client.getUsers();

      return response.map((jsonMap: JSONValue) =>
        User.fromJson(jsonMap as { [x: string]: any })
      );
    },
    getUsersForRange: async (start: number, end: number) => {
      const response: { users: JSONArray; totalCount: number } =
        await client.getUsersForRange(start, end);

      const users = response.users.map((jsonMap: JSONValue) =>
        User.fromJson(jsonMap as { [x: string]: any })
      );

      return { users, totalCount: response.totalCount };
    },
    getProfilePicture: async (pictureId: UUID) => {
      const response: Blob | null = await client.getProfilePicture(pictureId);
      return response;
    },
    add: async (user: User) => {
      const response: { [x: string]: unknown } = await client.addUser(
        user.toJson()
      );
      return User.fromJson(response);
    },
    update: async (user: User, currentPassword?: string) => {
      const response: { [x: string]: unknown } = await client.updateUser(
        user.toJson(),
        currentPassword
      );
      return User.fromJson(response);
    },
  };
};
