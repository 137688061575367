/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { usersJson } from './users';

export const adminsJson: { [x: string]: any }[] = [
  {
    id: usersJson[0].id,
    type: 'admin',
    email: usersJson[0].email,
    first_name: usersJson[0].first_name,
    last_name: usersJson[0].last_name,
    company_id: 'foo',
  },
  {
    id: 'admin2',
    type: 'admin',
    email: 'admin2@example.com',
    first_name: 'Hugo',
    last_name: 'Myers',
    company_id: 'foo',
  },
];
