import { createTheme } from '@mui/material';
import { colors } from './colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.error,
    },
    background: {
      default: colors.main.background,
      paper: colors.bright,
    },
  },
  shape: {
    borderRadius: 24,
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '8px !important',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: '8px !important',
          fontSize: '12px !important',
          maxWidth: '200px !important',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '8px !important',
        },
      },
    },
  },
});
