import { Box, InputAdornment } from '@mui/material';
import { FC, useState } from 'react';
import { ReactComponent as Visibility } from '../../../../assets/visibility.svg';
import { ReactComponent as VisibilityOff } from '../../../../assets/visibility_off.svg';
import { TextFormField } from '../TextFormField.component';
import { BasicFormProps } from '../types';

type PasswordFormFieldProps = BasicFormProps;

export const PasswordFormField: FC<PasswordFormFieldProps> = ({
  control,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const props = { ...rest, type: showPassword ? 'text' : 'password' };

  return (
    <TextFormField
      {...props}
      control={control}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Box
              sx={{
                width: '20px',
                height: '20px',
                padding: 0,
                cursor: 'pointer',
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </Box>
          </InputAdornment>
        ),
      }}
    />
  );
};
