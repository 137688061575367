import { useEffect, useState } from 'react';
import { Button } from '../../core/components/button';
import { PasswordConfirmationDialog } from '../../core/components/confirmation-dialog/PasswordConfirmationDialog';
import { MainPageLayout } from '../../core/components/layout';
import { Admin } from '../../core/models/admin.model';
import { useAdminStore } from '../../core/store/admin-store';
import { useCompanyStore } from '../../core/store/company-store/useCompanyStore';
import { useThrowError } from '../../core/store/error.store';
import { AdminDialog } from './AdminDialog.component';
import { AdminTable } from './AdminTable.component';

export const AdminManagement = () => {
  //region STATE
  const { admins, refresh, removeAdmin: remove } = useAdminStore();
  const { selectedCompany } = useCompanyStore();

  const [selectedAdmin, setSelectedAdmin] = useState<Admin | undefined>();
  const [showAdminDialog, setShowAdminDialog] = useState(false);

  const [adminToBeRemoved, setAdminToBeRemoved] = useState<
    string | undefined
  >();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const throwError = useThrowError();
  //endregion

  //region LIFECYCLE
  useEffect(() => {
    if (selectedCompany?.id !== undefined) {
      refresh()
        .then(() => console.debug('fetched admins'))
        .catch(console.error);
    }
  }, [selectedCompany?.id]);
  //endregion

  //region HANDLERS
  const onDialogClose = async (admin?: Admin) => {
    if (admin) {
      await refresh();
    }
    setShowAdminDialog(false);
    setSelectedAdmin(undefined);
  };

  const showAdmin = (user: Admin) => {
    setSelectedAdmin(user);
    setShowAdminDialog(true);
  };

  const confirmAdminRemoval = (admin: Admin) => {
    setAdminToBeRemoved(admin.id);
    setShowConfirmDialog(true);
  };

  const removeAdmin = async (password: string) => {
    if (adminToBeRemoved) {
      try {
        await remove(adminToBeRemoved, password);
        setShowConfirmDialog(false);
        setAdminToBeRemoved(undefined);
        await refresh();
      } catch (e) {
        throwError(e);
      }
    }
  };
  //endregion

  return (
    <MainPageLayout
      action={
        <Button
          variant="contained"
          sx={{ width: 200 }}
          onClick={() => setShowAdminDialog(true)}
        >
          + Add admin
        </Button>
      }
      title={'Administrator Management'}
    >
      {showAdminDialog && (
        <AdminDialog onClose={onDialogClose} selectedAdmin={selectedAdmin} />
      )}

      {showConfirmDialog && (
        <PasswordConfirmationDialog
          open={showConfirmDialog}
          description="Please enter your password to confirm the removal."
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={removeAdmin}
        />
      )}

      <AdminTable
        admins={admins}
        onEdit={showAdmin}
        onRemove={confirmAdminRemoval}
      />
    </MainPageLayout>
  );
};
