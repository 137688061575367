import { Box, Stack, styled } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as AdminManagementSvg } from '../../assets/admin_management.svg';
import { ReactComponent as CrossContentSvg } from '../../assets/cross_content_icon.svg';
import { ReactComponent as FrappLogoWithTextSvg } from '../../assets/frapp_logo_with_text_small.svg';
import { ReactComponent as SettingsSvg } from '../../assets/settings.svg';
import { ReactComponent as UserManagementSvg } from '../../assets/user_management.svg';
import { useSessionStore } from '../store/session-store';
import { colors, dimensions } from '../theme';
import { hexToRgbA } from '../theme/utils';
import { CompanySelector } from './CompanySelector.component';
import { routes } from './routes';

const StyledBox = styled(Box, { name: 'SideMenu', slot: 'side-menu' })({
  height: `calc(100% - (2 * ${dimensions.sidebar.padding.Y}))`, // substract padding top and bottom
  width: `calc(${dimensions.sidebar.width} - (2 * ${dimensions.sidebar.padding.X}))`,
  padding: `${dimensions.sidebar.padding.Y} ${dimensions.sidebar.padding.X}`,
  backgroundColor: `${colors.sidebar.background}`,
});

export const SideMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const selfUser = useSessionStore((state) => state.selfUser);

  const navigateToRoute = (route: string) => {
    if (location.pathname !== route) {
      navigate(route);
    }
  };

  return (
    <StyledBox>
      <Stack>
        <FrappLogoWithTextSvg
          style={{ marginLeft: '8px', marginBottom: '54px' }}
        />
        {selfUser?.isSuperAdmin && (
          <Box sx={{ marginBottom: 4 }}>
            <CompanySelector />
          </Box>
        )}
        <Stack spacing={'16px'}>
          <NavigationButton
            icon={<AdminManagementSvg />}
            title="Administrators"
            active={location.pathname === routes.admins}
            onClick={() => navigateToRoute(routes.admins)}
          />
          <NavigationButton
            icon={<UserManagementSvg />}
            title="Users"
            active={location.pathname === routes.users}
            onClick={() => navigateToRoute(routes.users)}
          />
          <NavigationButton
            icon={<CrossContentSvg />}
            title="Cross Content Hub"
            active={location.pathname === routes.crossContentHub}
            onClick={() => navigateToRoute(routes.crossContentHub)}
          />
          <NavigationButton
            icon={<SettingsSvg />}
            title={t('settings.title')}
            active={location.pathname === routes.settings}
            onClick={() => navigateToRoute(routes.settings)}
          />
        </Stack>
      </Stack>
    </StyledBox>
  );
};

type NavigationButtonProps = {
  icon: React.ReactNode;
  title: string;
  active: boolean;
  onClick: () => void;
};

const NavigationButton: FC<NavigationButtonProps> = ({
  icon,
  title,
  active,
  onClick,
}) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      sx={{
        height: '24px',
        padding: '12px 16px',
        background: active ? hexToRgbA(colors.bright, 0.16) : 'inherit',
        color: colors.font.bright,
        borderRadius: '24px',
        cursor: 'pointer',
      }}
      spacing={'12px'}
      onClick={onClick}
    >
      {icon} <span>{title}</span>
    </Stack>
  );
};
