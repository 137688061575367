import { ContentItemFormValues } from '../../../models/content-item-form-values';

export class TranslatedContentItemDto {
  localeId: number;

  title: string;

  constructor(localeId: number, title: string) {
    this.localeId = localeId;
    this.title = title;
  }

  static fromContentItemFormValues(formData: ContentItemFormValues) {
    return new TranslatedContentItemDto(
      formData.defaultLocale ?? 1,
      formData.label
    );
  }
}
