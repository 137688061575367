/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { Avatar as MuiAvatar, styled } from '@mui/material';
import { FC } from 'react';

import { ReactComponent as AvatarIcon } from '../../../assets/avatar_default.svg';
import { User } from '../../models/user.model';

export type AvatarProps = {
  user?: User | null;
  profilePicture?: Blob | null;
  toggleMenuOpen?: () => void;
};

const StyledAvatar = styled(MuiAvatar, { name: 'Avatar', slot: 'avatar' })({
  position: 'relative',
  cursor: 'pointer',
});

export const Avatar: FC<AvatarProps> = ({
  user,
  profilePicture,
  toggleMenuOpen,
}) => {
  return (
    <StyledAvatar
      src={
        profilePicture !== undefined && profilePicture !== null
          ? URL.createObjectURL(profilePicture)
          : ''
      }
      onClick={toggleMenuOpen}
    >
      {user && user.firstName && user.lastName ? (
        `${user.firstName[0]}${user.lastName[0]}`
      ) : (
        <AvatarIcon />
      )}
    </StyledAvatar>
  );
};
