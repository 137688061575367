import _ from 'lodash';
import { FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';
import { client } from '../api/api.client';
import { ContentItemUpdateDto } from '../api/data/dto/content-item-update.dto';
import { ContentItemDto } from '../api/data/dto/content-item.dto';
import { TranslatedContentItemDto } from '../api/data/dto/translated-content-item.dto';
import { UpdateSortingDto } from '../api/data/dto/update-sorting.dto';
import { ContentItemFormValues } from '../models/content-item-form-values';
import { ContentItem } from '../models/content-item.model';
import { useContentItemStore } from '../store/content-item-store';

interface BaseContentRepository {
  getContentItems(): Promise<void>;

  createContentItem(contentItem: ContentItemFormValues): Promise<void>;

  updateContentItem(
    updateData: ContentItemFormValues,
    dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<FieldValues>>>
  ): Promise<void>;

  deleteContentItem(id: string): Promise<void>;

  updateSortingOfContentItems(order: UpdateSortingDto[]): Promise<void>;
}

type ContentRepository = BaseContentRepository;

function checkForIconChange(
  dirtyUpdateData: Partial<ContentItemFormValues>
): Partial<ContentItemFormValues> {
  if (dirtyUpdateData.imagePath !== undefined) {
    const newIconPath = dirtyUpdateData.imagePath;
    delete dirtyUpdateData.imagePath;
    return {
      ...dirtyUpdateData,
      icon: newIconPath,
    };
  }
  return dirtyUpdateData;
}

function createUpdateResource(
  updateData: ContentItemFormValues,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<FieldValues>>>
) {
  if (!updateData.id) return;
  const dirtyUpdateData = _.pick(updateData, _.keys(dirtyFields));
  const dirtyUpdateDataWithIcon = checkForIconChange(dirtyUpdateData);
  return new ContentItemUpdateDto(dirtyUpdateDataWithIcon, updateData.id, [
    TranslatedContentItemDto.fromContentItemFormValues(updateData),
  ]);
}

export const useContentItemRepository = (): ContentRepository => {
  // TODO: reverse dependency store ---use---> repository
  const contentItemStore = useContentItemStore();

  return {
    async getContentItems(): Promise<void> {
      const contentItemsJson = await client.getCrossContentItems();

      const contentItems =
        contentItemsJson.length !== 0
          ? ContentItem.fromJsonArray(contentItemsJson)
          : [];

      contentItemStore.setContentItems(contentItems);
    },

    async createContentItem(contentItem): Promise<void> {
      contentItemStore.setLoading(true);
      const contentItemResource: ContentItemDto =
        ContentItemDto.fromForm(contentItem);
      await client.createCrossContentItem(contentItemResource);
      await this.getContentItems();
    },

    async updateContentItem(updateData, dirtyFields): Promise<void> {
      const updateResource = createUpdateResource(updateData, dirtyFields);
      if (updateResource) {
        await client.updateCrossContentItem(updateResource);
        await this.getContentItems();
      }
    },

    async deleteContentItem(id: string): Promise<void> {
      await client.deleteCrossContentItem(id);
      await this.getContentItems();
    },

    async updateSortingOfContentItems(
      order: UpdateSortingDto[]
    ): Promise<void> {
      const contentItemsJson = await client.updateSortingOfContentItems(order);

      const contentItems =
        contentItemsJson.length !== 0
          ? ContentItem.fromJsonArray(contentItemsJson)
          : [];

      contentItemStore.setContentItems(contentItems);
    },
  };
};
