import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useCompanyStore } from '../store/company-store/useCompanyStore';
import { colors } from '../theme';

export const CompanySelector: FC = () => {
  const { selectedCompany, companies, setSelectedCompany } = useCompanyStore();

  const [selectedCompanyId, setSelectedCompanyId] = useState('');

  useEffect(() => {
    setSelectedCompanyId(selectedCompany?.id ?? '');
  }, [selectedCompany?.id]);

  const handleCompanyChange = (event: SelectChangeEvent<string>) => {
    setSelectedCompany(event.target.value);
  };

  return (
    <>
      <Box sx={{ marginY: 1, color: colors.font.bright }}>Company</Box>
      <Select
        id="company-selector"
        value={selectedCompanyId}
        onChange={handleCompanyChange}
        sx={{
          width: '100%',
          color: colors.font.bright,
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: colors.font.bright,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.font.bright,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.font.bright,
          },
          '.MuiSvgIcon-root': {
            color: colors.font.bright,
          },
        }}
      >
        {companies?.map((company) => (
          <MenuItem key={company.id} value={company.id}>
            {company.key}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
