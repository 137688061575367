import { Stack, styled, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, ReactNode } from 'react';
import { ReactComponent as EditSvg } from '../../assets/edit.svg';

import { Avatar } from '../../core/components/avatar/Avatar.component';
import { RowAction, Table, TableRow } from '../../core/components/table';
import { User } from '../../core/models/user.model';

const StyledAvatarColumn = styled(Stack, {
  name: 'UserTable',
  slot: 'name',
})({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '.MuiTypography-root': {
    marginLeft: 16,
  },
});

const StyledRowTypography = styled(Typography, {
  name: 'UserTable',
  slot: 'row',
})({
  fontSize: '16px',
});

export interface UserTableProps {
  users: User[];
  totalUsers: number;
  profilePictures: (Blob | null)[];
  isLoading?: boolean;
  onUserClick: (user: User) => void;
  onPageChange: (startIndex: number, endIndex: number) => void;
}

export const UserTable: FC<UserTableProps> = ({
  users,
  totalUsers,
  profilePictures,
  isLoading,
  onUserClick,
  onPageChange,
}) => {
  const headers: ReactNode[] = [
    'Name',
    'E-Mail',
    'Last logged in',
    'Last agreed to terms',
    '', // Actions
  ];
  const rows: TableRow[] = [];

  for (let index = 0; index < users.length; index++) {
    const user = users[index];
    const profilePicture = profilePictures[index];

    const row: TableRow = {
      id: user.id ?? '',
      elements: [
        <StyledAvatarColumn key={0}>
          <Avatar user={user} profilePicture={profilePicture} />
          <StyledRowTypography>
            {user.firstName} {user.lastName}
          </StyledRowTypography>
        </StyledAvatarColumn>,
        <StyledRowTypography key={1}>{user.email}</StyledRowTypography>,
        <StyledRowTypography key={2}>
          {user.lastLoggedIn?.toLocaleString(DateTime.DATE_SHORT)}
        </StyledRowTypography>,
        <StyledRowTypography key={2}>
          {user.acceptedTosAt?.toLocaleString(DateTime.DATE_SHORT)}
        </StyledRowTypography>,
        <StyledRowTypography key={3}>
          <Stack direction={'row'} justifyContent={'flex-end'}>
            <RowAction onClick={() => onUserClick(user)}>
              <EditSvg />
            </RowAction>
          </Stack>
        </StyledRowTypography>,
      ],
    };
    rows.push(row);
  }

  return (
    <Table
      headers={headers}
      isLoading={isLoading}
      usePagination={true}
      rows={rows}
      totalRows={totalUsers}
      onPageChange={onPageChange}
    />
  );
};
