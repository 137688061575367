import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Slider,
} from '@mui/material';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import { Button } from '../button';
import { Completer } from '../../utils/completer';
import getCroppedImg, { PixelCrop } from './crop-image';

export interface ImageCropDialogFunctions {
  open: (image: string) => Promise<Blob>;
}

const ImageCropDialog: React.ForwardRefRenderFunction<
  ImageCropDialogFunctions,
  {}
> = (_props, ref) => {
  const cropCompleter = useMemo(() => new Completer<Blob>(), []);
  const [image, setImage] = useState<string>();
  const [open, setOpen] = useState(false);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<PixelCrop | null>(
    null
  );
  const [zoom, setZoom] = useState(1);

  useImperativeHandle(ref, () => ({
    open: (imageToOpen: string) => {
      setImage(imageToOpen);
      setOpen(true);
      return cropCompleter.promise;
    },
  }));

  const onClose = () => {
    setOpen(false);
  };

  const onCropClick = async () => {
    if (image != null && croppedAreaPixels != null) {
      const blob = await getCroppedImg(image, croppedAreaPixels, 0, {
        horizontal: false,
        vertical: false,
      });
      if (blob) cropCompleter.resolve(blob);
      else cropCompleter.reject('Error cropping image');
    }
    setOpen(false);
  };

  const onCropComplete = (croppedArea: Area, croppedAreaPixelsInput: Area) => {
    setCroppedAreaPixels(croppedAreaPixelsInput);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'}>
      <DialogContent>
        <Box
          height={300}
          width={'100%'}
          position={'relative'}
          borderRadius={1}
          overflow={'hidden'}
        >
          <Cropper
            objectFit={'contain'}
            cropShape={'round'}
            showGrid={false}
            image={image}
            crop={crop}
            zoom={zoom}
            onCropComplete={onCropComplete}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
          />
        </Box>
        <Slider
          value={zoom}
          color={'secondary'}
          min={1}
          max={3}
          step={0.1}
          onChange={(_, value) => setZoom(value as number)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant={'text'} onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onCropClick}>Crop</Button>
      </DialogActions>
    </Dialog>
  );
};
export default forwardRef(ImageCropDialog);
